import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addRoleData = (data: any) => {
  return axiosInstance.post("role", data);
};
const getRoleData = async () => {
  try {
    const response = await axiosInstance.get("role");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getRolePageResourceData = async () => {
  try {
    const response = await axiosInstance.get("rolepage");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getrolePermissionsData = async (id:number) => {
  try {
    const response = await axiosInstance.get(`rolePermissions/${id}`);
    return response;
  }catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateRoleData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`role`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updatePageRoleData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`role/permissions`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteRoleData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`role/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const RoleService = {   
    getRoleData,
    addRoleData,
    updateRoleData,
    deleteRoleData,
    getRolePageResourceData,
    updatePageRoleData,
    getrolePermissionsData
}

export default RoleService;