import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import LocationService from "../../services/location.service";

const postDataFailure = createAction<string>("Location/postDataFailure");
const getDataFailure = createAction<string>("Location/getDataFailure");
const deleteDataFailure = createAction<string>("Location/deleteDataFailure");

export interface PostCode {
    id?: number;
    postCode: string;
};
interface ApiResponse {
    success: boolean;
    message: string;
    postCode?: PostCode[];
};

type PostCodeApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    postCode: PostCode[] | null;
};

const initialState: PostCodeApiState = {
    status: "idle",
    error: null,
    postCode: null,
};

export const addlocation = createAsyncThunk(
    "addlocation",
    async (data: PostCode[], thunkAPI) => {
        try {
            const response = await LocationService.addLocationData(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAllLocation = createAsyncThunk(
    "getAllLocation",
    async (_, thunkAPI) => {
        try {
            const response = await LocationService.getLocationData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);


export const deleteLocation = createAsyncThunk(
    "deleteLocation",
    async (id: any, thunkAPI) => {
        try {
            const response = await LocationService.deleteLocationData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const locationSlice: any = createSlice({
    name: "location",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // post branch------------------->
            .addCase(addlocation.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addlocation.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addlocation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "contact Add failed";
            })

            // get branch------------------------->
            .addCase(getAllLocation.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getAllLocation.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.postCode = action.payload || [];
                    }
                }
            )
            .addCase(getAllLocation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch contact data";
            })

          
            //  delete branch --------------------------->
            .addCase(deleteLocation.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteLocation.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteLocation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete contact";
            })
    },
});

export default locationSlice.reducer;
