import { useEffect, useRef, useState } from "react";
import ChartComponent from "../../components/ChartComponent";
import PieChartComponent from "../../components/PiChart";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { fetchDashboardInfo } from "../../store/slice/order-slice";
import AuthService from "../../services/auth.service";
import DatePicker from "react-datepicker";
import CommonDateService from "../../services/common-date.service";
import Loading from "../../components/Loading/loading";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { dashboard } = useSelector((state: any) => state.order);
  const [reportType, setReportType] = useState<string>("weekly");
  const [username, setUserName] = useState<string | null>("");
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState<Date>(CommonDateService.addDays(7));
  const [isLoading, setIsLoading] = useState(true);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let name = AuthService.getUserName();
      setUserName(name);
      const weekDates = CommonDateService.getWeekStartAndEnd(new Date());
      let data = {
        startDate: CommonDateService.formatDate(weekDates.start),
        endDate: CommonDateService.formatDate(weekDates.end),
      };
      dispatch(fetchDashboardInfo(data)).finally(() => {
        setIsLoading(false);
      });
    }
    return () => {
      effectServiceCall.current = true;
    };
  }, [dispatch]);

  const formattedDate = today.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const callDispatch = (data: any) => {
    setIsLoading(true);
    dispatch(fetchDashboardInfo(data)).finally(() => {
      setIsLoading(false);
    });
  };

  const handleReportTypeChange = (type: string) => {
    setReportType(type);
    switch (type) {
      case "weekly":
        const weekDates = CommonDateService.getWeekStartAndEnd(new Date());
        let data = {
          startDate: CommonDateService.formatDate(weekDates.start),
          endDate: CommonDateService.formatDate(weekDates.end),
        };
        callDispatch(data);
        break;

      case "LastWeek":
        const lastweekDates = CommonDateService.getLastWeekStartAndEnd(
          new Date()
        );
        let lastweekdata = {
          startDate: CommonDateService.formatDate(lastweekDates.start),
          endDate: CommonDateService.formatDate(lastweekDates.end),
        };
        callDispatch(lastweekdata);
        break;

      case "monthly":
        const monthDates = CommonDateService.getMonthStartAndEnd(new Date());
        let dataMonthly = {
          startDate: CommonDateService.formatDate(monthDates.start),
          endDate: CommonDateService.formatDate(monthDates.end),
        };
        callDispatch(dataMonthly);
        break;

      case "custom":
        let custom = {
          startDate: CommonDateService.formatDate(startDate),
          endDate: CommonDateService.formatDate(endDate),
        };
        callDispatch(custom);
        break;
      default:
        break;
    }
  };

  const onChangeStartDate = (date: Date) => {
    let custom = {
      startDate: CommonDateService.formatDate(date),
      endDate: CommonDateService.formatDate(endDate),
    };
    dispatch(fetchDashboardInfo(custom));
  };

  const onChangeEndDate = (date: Date) => {
    let custom = {
      startDate: CommonDateService.formatDate(startDate),
      endDate: CommonDateService.formatDate(date),
    };
    dispatch(fetchDashboardInfo(custom));
  };

  return (
    <>
      <div className="flex flex-col justify-start gap-3 items-start h-screen w-full p-4 md:p-6">
        <div className="w-full flex-col justify-start items-start flex gap-1">
          <h1 className="text-2xl">Welcome back, {username}</h1>
          <p className="text-gray-400 text-sm">{formattedDate}</p>

          {isLoading && <Loading />}

          {/* Button section */}
          <div className="flex flex-wrap gap-2 sm:gap-4 my-4 w-full">
            <button
              className={`py-2 px-4 rounded transition-all duration-200 hover:bg-blue-600 hover:text-white ${
                reportType === "weekly"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => handleReportTypeChange("weekly")}
            >
              Weekly
            </button>

            <button
              className={`py-2 px-4 rounded transition-all duration-200 hover:bg-blue-600 hover:text-white ${
                reportType === "LastWeek"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => handleReportTypeChange("LastWeek")}
            >
              Last Week
            </button>

            <button
              className={`py-2 px-4 rounded transition-all duration-200 hover:bg-blue-600 hover:text-white ${
                reportType === "monthly"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => handleReportTypeChange("monthly")}
            >
              Monthly
            </button>

            <button
              className={`py-2 px-4 rounded transition-all duration-200 hover:bg-blue-600 hover:text-white ${
                reportType === "custom"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => handleReportTypeChange("custom")}
            >
              Custom
            </button>

            {reportType === "custom" && (
              <div className="flex flex-col w-full gap-2">
                <DatePicker
                  selected={startDate}
                  className="w-full p-2 border rounded-sm"
                  onChange={(date: any) => {
                    setStartDate(date);
                    onChangeStartDate(date);
                  }}
                />
                <DatePicker
                  selected={endDate}
                  className="w-full p-2 border rounded-sm"
                  onChange={(date: any) => {
                    setEndDate(date);
                    onChangeEndDate(date);
                  }}
                />
              </div>
            )}
          </div>

          {/* Four Boxes in a Row */}
          <div className="flex justify-between gap-4 w-full">
            <div className="flex-1 bg-white shadow-sm rounded-sm border p-4 text-center">
              <h2 className="text-xl">{dashboard?.totalSalesAmount}</h2>
              <p className="text-gray-400 text-sm">Total Revenue</p>
            </div>
            <div className="flex-1 bg-white shadow-sm rounded-sm border p-4 text-center">
              <h2 className="text-xl">{dashboard?.totalOrdersCount}</h2>
              <p className="text-gray-400 text-sm">Total Orders</p>
            </div>
            <div className="flex-1 bg-white shadow-sm rounded-sm border p-4 text-center">
              <h2 className="text-xl">{dashboard?.totalDeliveredCount}</h2>
              <p className="text-gray-400 text-sm">Delivered</p>
            </div>
            <div className="flex-1 bg-white shadow-sm rounded-sm border p-4 text-center">
              <h2 className="text-xl">{dashboard?.pendingOrdersCount}</h2>
              <p className="text-gray-400 text-sm">Pending Orders</p>
            </div>
          </div>

          {/* Revenue Chart Section */}
          <div className="mt-4 bg-white shadow-sm rounded-sm border p-4 w-full">
            <div className="flex justify-start items-start">
              <p className="text-xl">Revenue chart</p>
            </div>
            <div className="flex justify-center items-center w-full px-5 h-auto">
              <ChartComponent revenueList={dashboard?.dashboardRevenue} />
            </div>
          </div>

          {/* By Service Pie Chart Section */}
          <div className="mt-4 bg-white shadow-sm rounded-sm border p-4 w-full">
            <p className="text-xl text-left mb-2">By Service</p>
            <div className="flex flex-col justify-center items-center w-full h-auto">
              <PieChartComponent
                serviceList={dashboard?.dashboardPieChartByProduct}
              />
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Dashboard;
