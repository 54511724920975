import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import AuthService from "../../services/auth.service";

type User = {
  email: string;
  password: string;
};

type NewUser = User & {
  persionFirstName: string;
  persionLastName: string;
  Name: string;
  companyAddress: string;
  companyLogoUrl: string;
  phoneNumber: string;
};

type UserBasicInfo = {
  id: string;
  name: string;
  email: string;
};

type UserProfileData = {
  name: string;
  email: string;
};

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  userProfileData?: UserProfileData | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

const initialState: AuthApiState = {
  basicUserInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
  userProfileData: undefined,
  status: "idle",
  error: null,
};

// export const login = createAsyncThunk(
//   "auth/login",
//   async (user:any, thunkAPI) => {
//     try {
//       const data = await AuthService.login(user);
//       return { user: data };
//     } catch (error) {
//     //   const message =
//     //     (error.response &&
//     //       error.response.data &&
//     //       error.response.data.message) ||
//     //     error.message ||
//     //     error.toString();
//     //  thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const login = createAsyncThunk(
  "auth/login",
  async (data: User, thunkAPI) => {
    try {
      const response = await AuthService.login(data);
      const resData = response.data;
      return resData;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(message(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const FRegister = createAsyncThunk(
  "tenant",
  async (data: NewUser, thunkAPI) => {
    try {
      const response = await AuthService.register(data);
      const resData = response.data;
      return resData;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(message(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  AuthService.logout();
});


export const getUser = createAsyncThunk(
  "dashboard",
  async (userId: string) => {
    const response = await axiosInstance.get(
      `/dashboard/${userId}`
    );
    console.log(response);
    return response.data;
  }
);

export const getCurrentUserData = createAsyncThunk(
  "getCurrentUserData",
  async () => {
    try {
      const currentUser = AuthService.getCurrentUser();
      return currentUser;
    } catch (error) {
      throw new Error("Failed to get current user data");
    }
  }
);

const authSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder
      // .addCase(login.fulfilled,(state, action: any) =>{
      //     state.status = "idle";
      //     state.basicUserInfo = action.payload;
      // })
      // .addCase(login.pending, (state, action: any) => {
      //   state.status = "loading";
      //   state.error = null;
      // })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<UserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Login failed";
      })

      .addCase(FRegister.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        FRegister.fulfilled,
        (state, action: PayloadAction<UserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
        }
      )
      .addCase(FRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Registration failed";
      })

      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        state.basicUserInfo = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })

      // .addCase(getUser.pending, (state) => {
      //   state.status = "loading";
      //   state.error = null;
      // })
      // .addCase(getUser.fulfilled, (state, action) => {
      //   state.status = "idle";
      //   state.userProfileData = action.payload;
      // })
      // .addCase(getUser.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error.message || "Get user profile data failed";
      // });

      .addCase(getCurrentUserData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCurrentUserData.fulfilled, (state, action) => {
        state.status = "idle";
        // state.basicUserInfo = action.payload !== null ? action.payload : null;
      })
      .addCase(getCurrentUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to get current user data";
      });
  },
});

export default authSlice.reducer;