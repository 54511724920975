import { useState } from "react";
import { MdDoubleArrow } from "react-icons/md";



function Header({ onMobileSidebar }: { onMobileSidebar: any }) {


    return (
        <>
            <div className="w-full z-50 fixed h-fit">
                <div className="flex flex-row item-end justify-end w-full">
                    <button className="hidden md:flex bg-green-dark left-0 pl-2 pr-0.5 h-10 items-center rounded-l-lg justify-center md:mt-4"
                        onClick={onMobileSidebar}
                    >
                        <MdDoubleArrow className="w-7 h-7 text-white rotate-180" />
                    </button>
                </div>
            </div>
        </>
    );
}
export { Header };