import React, { useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { login } from "../../store/slice/auth-slice";
import { useNavigate } from "react-router-dom";
import { object, string } from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/Loading/loading";

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = object().shape({
        email: string()
            .required('Email is required')
            .email('Email is invalid'),
        password: string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        // acceptTerms: bool().oneOf([true], 'Accept Terms is required')
    });

    const {
        register,
        handleSubmit,
        // reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        setIsLoading(true);
        dispatch(login(data))
            .unwrap()
            .then(() => {
                navigate("/dashboard");
            })
            .catch(() => {
                setIsLoading(false);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 200);
            });
    };
    return (
        <div className="w-full bg-white-full p-52">
            <div className="flex justify-center items-center w-full">
                <form className="w-full flex flex-col justify-center items-center mx-auto gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex text-3xl font-medium text-black ">
                        <p>Login</p>
                    </div>
                    <div className="flex flex-col w-96 gap-3">
                        {(isLoading) && <Loading></Loading>}
                        <div className="relative">
                            <input type="email" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm   block w-full p-2.5 focus:ring-green-dark dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.email ? 'is-invalid' : ''}`} placeholder="Email" {...register('email')} />
                            <div className="font-medium text-left mt-2 text-xs text-red-600" >{errors?.email?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <input type="password" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" {...register('password')} />
                            <div className="font-medium mt-2 text-left text-xs text-red-600" >{errors?.password?.message?.toString()}</div>
                        </div>
                        <div className="flex justify-start items-start float-left">
                            <button className="flex gap-3 py-3 p-3 px-6 me-2 text-sm font-medium text-white-full bg-green-dark rounded-lg border border-gray-200 hover:black hover:opacity-90" type="submit">
                                <p>Login</p>
                            </button>
                        </div>
                        {/* <div className="flex justify-start items-start float-left">
                            <a href="/resetPassword" className="text-red-700 text-sm hover:underline ">Forgot Password?</a>
                        </div> */}
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Login;