import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addLocationData = (data: any) => {
  return axiosInstance.post("servicelocation", data);
};
const getLocationData = async () => {
  try {
    const response = await axiosInstance.get("servicelocation");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};


const deleteLocationData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`servicelocation/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const LocationService = {   
    addLocationData,
    getLocationData,
    deleteLocationData
}

export default LocationService;