import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../store/hooks/redux-hooks';
import { useSelector } from 'react-redux';
import { getBookingServicePricePlanData } from '../../store/slice/bookingServicePricePlan-Slice';
import { boolean, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getBookingServiceData } from '../../store/slice/bookingservice-slice';

const AddServiceStepTwo = ({ onSubmitStep, initialData, onPreviousStep }: any) => {
    const { bookingServicePricePlanList } = useSelector((state: any) => state.ServicePricePlan);
    const { bookingServiceList } = useSelector((state: any) => state.bookingService);
    const [filteredServiceList, setFilteredServiceList] = useState<any[]>();
    const [isAddonValue, setAddOnValue] = useState(false);

    const [file, setFile] = useState<File>();
    const [imageUrl, setImageUrl] = useState<string>();
    const dispatch = useAppDispatch();

    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            setValue("isAddOn", false);
            dispatch(getBookingServicePricePlanData());
            dispatch(getBookingServiceData());
            effectSeviceCall.current = true;
        }
    }, [dispatch]);

    useEffect(()=>{
        if(bookingServiceList!=null && bookingServiceList.length>0){
            console.log(bookingServiceList)

            // let filededlist = bookingServiceList.filter((bookingService: any) => {
            //     const { serviceName, Location, id } = bookingService;
            //     const searchValue = '';
            //     const idString = typeof id === 'string' ? id : String(id);
            //     return (
            //         (serviceName && serviceName.toLowerCase().includes(searchValue)) ||
            //         (Location && Location.toLowerCase().includes(searchValue)) ||
            //         (idString && idString.toLowerCase().includes(searchValue))
            //     );
        
            // })
            setFilteredServiceList(bookingServiceList);
        }
    }, [bookingServiceList])

    const validationSchema = object().shape({
        pricingPlanId: number()
             .transform((value) => Number.isNaN(value) ? null : value )
            .required('pricingPlan is required'),
        pricingtype: string()
            .required('Pricingtype is required'),
        amount: number()
            .required('amount Name is required'),
        paymentReference: string().required('paymentReference is required'),
        parentServiceId: number().transform((value) => Number.isNaN(value) ? null : value ),
        isAddOn: boolean().required('Add On is required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        if(file== null){
            alert("Please upload a Image")
            return;
        }
        data.file = file;
        onSubmitStep(data);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files as FileList;
        if(selectedFiles!=null && selectedFiles.length > 0){
            setFile(selectedFiles?.[0]);
            setImageUrl(URL.createObjectURL(selectedFiles?.[0]));
        }
    }

    const handleRadioChange = (e: any) => {
        if(e.target.value === "false"){
            setAddOnValue(false)
        }else{
            setAddOnValue(true)
        }
        setValue("isAddOn",e.target.value);
      };

    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="md:flex md:flex-col flex flex-col justify-start items-start gap-3  w-full py-2">
                    <div className="w-full flex ">
                        <div className="border flex flex-col gap-7 items-start justify-start p-[22px] sm:px-5 rounded-md bg-white-full  shadow-bs w-full">
                            <div className="flex flex-col items-start justify-start w-full md:w-full">
                                Price Plan
                                {/* <div className="flex flex-col h-auto inset-[0] items-center justify-center m-auto  rounded-lg w-full">
                                    <div className="flex flex-col gap-[30px] text-gray-400 items-start justify-start mt-[10px] w-full">

                                        choose how clients can pay for this service
                                    </div>
                                </div> */}
                            </div>
                            <div
                                className="sm:flex-col flex-row gap-[25px] grid justify-start grid-cols-3 md:ml-[0] w-full">
                                <div className="relative max-w-sm col-span-1 w-full">
                                    <label className="block mb-2 text-left text-sm">
                                        Price Plan
                                    </label>
                                    <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500 placeholder:text-txtInterMedium14 ${errors.pricingPlanId ? 'is-invalid' : ''}`}

                                        {...register('pricingPlanId')}
                                    >
                                        {bookingServicePricePlanList?.map((item: any) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.pricingPlanId?.message?.toString()}</div>
                                </div>

                                <div className="relative max-w-sm col-span-1 w-full">
                                    <label className="block mb-2 text-left text-sm">
                                        Add Ons
                                    </label>
                                    <div className="flex space-x-4">
                                        <label className="flex items-center space-x-2">
                                            <input
                                            type="radio"
                                            name="option"
                                            value="true"
                                            defaultChecked ={false}
                                            onChange={(e:any) => {handleRadioChange(e)}}
                                            className="form-radio h-4 w-4 text-blue-600"
                                            />
                                            <span className="text-gray-700">Yes</span>
                                        </label>
                                        <label className="flex items-center space-x-2">
                                            <input
                                            type="radio"
                                            name="option"
                                            value="false"
                                            defaultChecked ={false }
                                            onChange={(e:any) => {handleRadioChange(e)}}
                                            className="form-radio h-4 w-4 text-blue-600"
                                            />
                                            <span className="text-gray-700">No</span>
                                        </label>
                                        </div>
                                </div>

                                        {isAddonValue  &&

                                            <div className="relative max-w-sm col-span-1 w-full">
                                            <label className="block mb-2 text-left text-sm">
                                            Select Parent Service for Add Ons
                                            </label>
                                            <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500 placeholder:text-txtInterMedium14 ${errors.parentServiceId ? 'is-invalid' : ''}`}

                                                {...register('parentServiceId')}
                                            >
                                                {filteredServiceList?.map((item: any, index: number) => (
                                                    <option key={index} value={item.serviceId}>{item.serviceName}</option>
                                                ))}
                                            </select>
                                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.parentServiceId?.message?.toString()}</div>
                                            </div>
                                        }
                             


                            </div>
                            <div className="flex flex-col items-start justify-start w-full md:w-full">
                                Price Per Session
                            </div>
                            <div className="sm:flex-col flex-row grid justify-start grid-cols-1 md:ml-[0] w-full">
                                <div className=" md:flex md:flex-col grid grid-cols-3 w-full gap-[25px]">
                                    <div className="relative max-w-sm col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Price Type
                                        </label>
                                        <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500 placeholder:text-txtInterMedium14`}
                                            {...register('pricingtype')}
                                        >
                                            <option value="Fixed price">Fixed price</option>
                                            <option value="Free">Free</option>
                                        </select>
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.pricingtype?.message?.toString()}</div>
                                    </div>
                                    <div className="relative max-w-sm col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Price Amount
                                        </label>
                                        <input type="number" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h `} placeholder="Rs e.g. 150"
                                            {...register('amount')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.amount?.message?.toString()}</div>
                                    </div>
                                    <div className="relative max-w-sm col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Price preference
                                        </label>
                                        <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500 placeholder:text-txtInterMedium14`}
                                            {...register('paymentReference')}
                                        >
                                            <option value="Entireamountonline">Entire amount online</option>
                                            <option value="Entireamountinperson">Entire amount in person</option>
                                            <option value="Entireamounteitheronlineorinperson">Entire amount either online or in person</option>
                                            <option value="Onlinedepositandtherestinperson">Online deposit and the rest in person</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:flex-col flex-row gap-[25px] grid justify-start grid-cols-1 md:ml-[0] w-full">
                                <div className="grid grid-cols-1 w-full gap-[25px]">
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.paymentReference?.message?.toString()}</div>
                                </div>
                            </div>

                            {/* <div className="flex flex-col items-start justify-start w-full md:w-full">
                                Image Selection
                            </div> */}
                            <div className="sm:flex-col flex-row grid justify-start grid-cols-1 md:ml-[0] w-full">
                                <div className=" md:flex md:flex-col grid grid-cols-3 w-full gap-[25px]">
                                <div className="relative max-w-sm col-span-1 w-full">
                                       <input type="file" onChange={handleChange} />
                                        {/* <div className="font-medium mt-2 text-xs text-red-600" >{errors?.pricingtype?.message?.toString()}</div> */}
                                    </div>
                                </div>

                                {imageUrl && (
                                    <div>
                                       <img className="preview w-64" src={imageUrl} alt="" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex fles-row md:flex-col justify-between items-end h-auto w-full gap-2 mt-5 ">
                        <div className="flex fles-row gap-2 justify-end items-center mt-3">
                            <button type='button' onClick={onPreviousStep} className="flex gap-2 px-4 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange">
                                <p className="mt-0.5">Previous</p>
                            </button>
                        </div>
                        <div className="flex fles-row gap-2 justify-end items-center mt-3">
                            <button type="submit" className="flex gap-2 px-4 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange">
                                <p className="mt-0.5">Save</p>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddServiceStepTwo;
