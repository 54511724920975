import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import ContactService from "../../services/contact.service";
import { NewContact } from "../model/contactmodel";

const postDataFailure = createAction<string>("Contact/postDataFailure");
const getDataFailure = createAction<string>("Contact/getDataFailure");
const deleteDataFailure = createAction<string>("Contact/deleteDataFailure");

interface ApiResponse {
    success: boolean;
    message: string;
    // branchName: string;
    contactList?: NewContact[];
    ContactBranches: ContactBranches[];
    ContactInfoAssigns: ContactInfoAssigns[];
};
export interface ContactBranches {
    ContactId: string;
    BranchId: string;
}

export interface ContactInfoAssigns {
    staffId: String;
    contactId: String;
    
}
type ContactApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    contactList: NewContact[] | null;
};

const initialState: ContactApiState = {
    status: "idle",
    error: null,
    contactList: null,
};

export const addContact = createAsyncThunk(
    "addContact",
    async (data: NewContact, thunkAPI) => {
        try {
            const response = await ContactService.addContact(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchContactData = createAsyncThunk(
    "fetchContactData",
    async (_, thunkAPI) => {
        try {
            const response = await ContactService.getContactData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const putContactData = createAsyncThunk(
    "putContactData",
    async ({ id, data }: { id: any; data: NewContact }, thunkAPI) => {
        try {
            const response = await ContactService.updateContactData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteContact = createAsyncThunk(
    "deleteContact",
    async (id: any, thunkAPI) => {
        try {
            const response = await ContactService.deleteContactData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const contactSlice:any = createSlice({
    name: "contact",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

// post contact------------------->
            .addCase(addContact.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addContact.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "contact Add failed";
            })

// get contact------------------------->
            .addCase(fetchContactData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fetchContactData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if(action.payload != null ){
                    state.contactList = action.payload || [];
                    }
                }
            )
            .addCase(fetchContactData.rejected, (state, action) => {
                state.status = "failed";
                console.log(action.error)
                state.error = action.error.message || "Failed to fetch contact data";
            })

// update contact --------------------->
            .addCase(putContactData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                putContactData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if(action.payload != null ){
                    state.contactList = action.payload || [];
                    }
                }
            )
            .addCase(putContactData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update contact data";
            })

//  delete contact --------------------------->
            .addCase(deleteContact.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            
            .addCase(deleteContact.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })
            
            .addCase(deleteContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete contact";
            })
    },
});

export default contactSlice.reducer;
