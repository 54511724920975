// src/ChartComponent.tsx
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

interface RevenuListProps {
  revenueList: any[];
}


const ChartComponent: React.FC<RevenuListProps> = ({revenueList}) => {
  const [optionData, setData] = useState<any>(null);

  useEffect(() => {
    if (revenueList != null && revenueList.length > 0 ) {
      let option =  {
        labels: revenueList.map((x: any) => {return x.orderDate.split('T')[0]}),
        datasets: [
          {
            label: 'Revenue',
            data: revenueList.map((x: any) => {return x.totalSales}),
            fill: false,
            backgroundColor: '#063d6b',
            borderColor: '#063d6b',
          },
        ],
      };

      setData(option);
    }else{   
      setData(null);
    }
  }, [revenueList]);

  return (
    // <div className="bg-white justify-center items-center flex w-full h-72 ">
   <>
    {(optionData!=null) && 
      <Line className='w-fit h-fit ' data={optionData} options={options} />
    }
   </>
    // </div>
  );
};

export default ChartComponent;
