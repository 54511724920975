import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { deleteBranch, getBranch } from '../../../store/slice/branch-slice';

function DeleteBranch({ openDelModal, delId, Close }: { openDelModal: boolean, delId: any, Close: any, }) {
    const [deleteId, setDeleteId] = useState(null);
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        setDeleteId(delId);
        if (deleteId) {
            dispatch(deleteBranch(deleteId)).then(() => {
                Close();
                setDeleteId(null);
                dispatch(getBranch());
            });
        }
    };


    return (
        <Modal className='backdrop-blur-sm backdrop-brightness-75  p-7 shadow-sm' show={openDelModal} size="md" onClose={() => Close()} popup>
            <Modal.Header className='bg-white-full' />
            <Modal.Body className='bg-white-full'>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500">
                        Are you sure you want to delete this Branch?
                    </h3>
                    <div className="flex justify-between gap-4 px-5 pb-3">
                        <button
                            type="button"
                            className="flex gap-3 p-2  text-sm font-medium text-red-600 bg-transparent rounded-md border-2 hover:border-black "
                            onClick={handleDelete}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="flex gap-3 p-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                            onClick={Close}
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { DeleteBranch };
