import React, { useState } from "react";
import PieChartComponent from "../../components/PiChart";
import ChartComponent from "../../components/ChartComponent";
import useComponentVisible from "../../components/useComponentVisible";
import Dropdown from "../../components/Dropdown/Dropdown";


const Test: React.FC = () => {
    // const [isOpen, setIsOpen] = useState(false);

    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);

    const data = [
        {
            "id": "1",
            "name": "Minnie Barrett",
            "imageUrl":
                "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            "id": "2",
            "name": "Andy Holmes",
            "imageUrl":
                "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            "id": "3",
            "name": "Felicia Watts",
            "imageUrl":
                "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            "id": "4",
            "name": "Hailey Green",
            "imageUrl":
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            "id": "5",
            "name": "Jeremiah Hughes",
            "imageUrl":
                "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            "id": "6",
            "name": "Amy Perkins",
            "imageUrl":
                "https://images.unsplash.com/photo-1587677171791-8b93c752999b?q=80&w=1949&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
    ];

    const handleSelect = (id: string) => {
        console.log(`Selected item with id ${id}`);
    };

    return (
        <>
            <div>
                <Dropdown
                    id='person'
                    title='Select Person'
                    data={data}
                    hasImage
                    style='bg-purple-800'
                    selectedId='3'
                    onSelect={handleSelect}
                />
                {/* <button onClick={() => setIsOpen(!isOpen)} classNameNameName="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dropdown button <svg classNameNameName="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
                    {isOpen ? "Close" : "Open"}
                </button>


                <div classNameNameName="dropdown_container">
                    <p>Option 1</p>
                    <p>Option 2</p>
                    <p>Option 3</p>
                </div> */}
            </div >




            <label className="inline-flex items-center mb-5 cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Default toggle</span>
            </label>


        </>
    );
};

export default Test;
