import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { addRole, getRole, updateRole } from '../../../store/slice/role-slice';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";

function AddRole({ openDrawer, Close, modalType, role }: { openDrawer: boolean, Close: any, modalType: string, role?:any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Role' : 'Edit Role'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingRole, setExistingRole] = useState(role);


    const validationSchema = object().shape({
        name: string()
            .required('name is required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        console.log(modalType);
        if (modalType === 'Add') {
            console.log(JSON.stringify(data, null, 2));
            dispatch(addRole(data))
                .unwrap()
                .then(() => {
                    setOpenModal(false)
                    dispatch(getRole())
                    reset();
                    navigate("/settings/role")
                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
        } else {

            console.log(JSON.stringify(data, null, 2));
            dispatch(updateRole({ id: role.id, data }))
                .unwrap()
                .then(() => {
                    setOpenModal(false)
                    dispatch(getRole())
                    navigate("/settings/role")
                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
        }

    };

    useEffect(() => {
        if (modalType === 'Edit' && role) {
            setExistingRole(role);
            reset(role);
        }
    }, [role, modalType, reset]);


    return (
        <Modal
            className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => setOpenModal(Close)}
            initialFocus={emailInputRef}
            size="lg"
        >
            <Modal.Header className="bg-white-full p-5">
                <span className="text-blue_gray-900 text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-full">
                    <div className="space-y-5 w-full">
                        <div className='p-5'>
                            <form className="mx-auto" onSubmit={handleSubmit(onSubmit)}>
                                <div className="space-y-3 max-w-lg">
                                    <div className="relative max-w-full col-span-2">
                                        <label className="block mb-2">
                                            Role Name
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.name ? 'is-invalid' : ''} `}
                                            placeholder="Enter Role Name"
                                            {...register('name')}
                                        />
                                        <div className="font-medium text-left mt-2 text-xs text-red-600" >{errors?.name?.message?.toString()}</div>
                                    </div>
                                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                                            <button onClick={() => {
                                                reset()
                                                setOpenModal(Close);
                                            }} type="button" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border border-gray-200">
                                                <p>Cancel</p>
                                            </button>
                                            <button type="submit" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                                <p>Save</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            </Modal.Body>
        </Modal>
    );
}

export { AddRole };
