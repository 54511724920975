import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const postStaffData = async (data: any) => {
  try {
    const response = await axiosInstance.post("Staff", data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getStaffData = async () => {
  try {
    const response = await axiosInstance.get("Staff");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getStaffProfile = async () => {
  try {
    const response = await axiosInstance.get("Staff/profileinfo");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getStaffGrantPermissionsData = async (id: number) => {
  try {
    const response = await axiosInstance.get(`grantpermission/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const postStaffeGrantPermissionsData = async (data: any) => {
  try {
    const response = await axiosInstance.post(`grantpermission`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateStaffData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`Staff/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteStaffData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`Staff/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const StaffService = {
  getStaffProfile,
  postStaffData,
  getStaffData,
  updateStaffData,
  deleteStaffData,
  getStaffGrantPermissionsData,
  postStaffeGrantPermissionsData
}

export default StaffService;