import { useState } from "react";

type ListItem = {
    name: string;
};

type MainFilterProps = {
    data: ListItem[];
    onFilterSelect: (filter: string) => void;
};
function MainFilter({ data, onFilterSelect  }: MainFilterProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleFilterClick = (filter: string) => {
        onFilterSelect(filter);
        setIsOpen(false);
    };

    return (
        <>
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="flex gap-3 py-1 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white text-center rounded-sm border border-gray-200 hover:bg-gray-100">
                <div className="fill-black">
                    <svg viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                </div>
                <p>Filters</p>
            </button>
            {isOpen && (
                <div className=" left-[250px]  md:w-32 md:end-0  z-50 border absolute mt-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 bg-white-full">
                    <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                        {data.map((item, index) => (
                            <li key={index}>
                                <p className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => handleFilterClick(item.name)}>{item.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export { MainFilter };
