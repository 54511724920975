import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SideBar, Header } from "../../components";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const MainLayout = () => {

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleSidebar = () => {
    setIsMobileOpen(!isMobileOpen);
  };
  return (
    <>
      <div className="dark:bg-boxdark-2 dark:text-bodydark w-full">
        <div className="flex h-screen overflow-hidden md:overflow-auto">
        <ToastContainer autoClose={2000} />
          <SideBar isMobileOpen={isMobileOpen} />
          <Header onMobileSidebar={toggleSidebar} />
          <div className="relative flex flex-1 flex-col overflow-hidden w-fit">
            {(!isMobileOpen) &&
              <main className="pl-3 bg-light-blue md:p-0 h-screen w-full">
                <div className="rounded-l-lg shadow-lg bg-white-full h-full w-full">
                  <div className="p-5 py-[50px] h-screen w-full overflow-auto">
                    <Outlet />
                  </div>
                </div>
              </main>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLayout;