import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import BookingServiceType from "../../services/bookingServiceType.Service";

const postDataFailure = createAction<string>("BookingserviceType/postDataFailure");
const getDataFailure = createAction<string>("BookingServiceType/getDataFailure");

type NewBookingServiceType = {
    serviceTypeName: string;
    description: string;
    imageUrl: string;
    category: string;

};

interface ApiResponse {
    success: boolean;
    message: string;
    bookingServiceTypeList?: NewBookingServiceType[];
};

type bookingServiceApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    bookingServiceTypeList: NewBookingServiceType[] | null;
};

const initialState: bookingServiceApiState = {
    status: "idle",
    error: null,
    bookingServiceTypeList: null,
};

export const addServiceTypeData = createAsyncThunk(
    "addServiceTypeData",
    async (data: NewBookingServiceType, thunkAPI) => {
        try {
            const response = await BookingServiceType.addServiceType(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getBookingServiceTypeData = createAsyncThunk(
    "getBookingServiceData",
    async (_, thunkAPI) => {
        try {
            const response = await BookingServiceType.getBookingServiceType();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const putBookingServiceData = createAsyncThunk(
    "putBookingServiceData",
    async ({ id, data }: { id: any; data: NewBookingServiceType }, thunkAPI) => {
        try {
            const response = await BookingServiceType.updateBookingServiceType(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const bookingServiceTypeSlice:any = createSlice({
    name: "serviceType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addServiceTypeData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addServiceTypeData.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addServiceTypeData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "bookingServiceType Add failed";
            })

//bookingServiceType get--------->
            .addCase(getBookingServiceTypeData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getBookingServiceTypeData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.bookingServiceTypeList = action.payload || [];
                }
            )
            .addCase(getBookingServiceTypeData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch getBookingService data";
            })

        //bookingServiceType update--------->
        .addCase(putBookingServiceData.pending, (state) => {
            state.status = "loading";
            state.error = null;
        })
        .addCase(
            putBookingServiceData.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    // state.bookingServiceList = action.payload || [];
                }
            }
        )
        .addCase(putBookingServiceData.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message || "Failed to update contact data";
        })
    },
});

export default bookingServiceTypeSlice.reducer;
