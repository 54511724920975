import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import TransactionService from "../../services/transaction.service";

//const postDataFailure = createAction<string>("order/postDataFailure");
const getDataFailure = createAction<string>("order/getDataFailure");
//const deleteDataFailure = createAction<string>("order/deleteDataFailure");


type OrderApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    orderList: any[] | null;
    dashboard: any;
    invoiceInfo:any | null;
    deliveryList: any[]| null;
    deliveryupdate:any | null;

};

const initialState: OrderApiState = {
    status: "idle",
    error: null,
    orderList: null,
    dashboard: null,
    invoiceInfo: null,
    deliveryList: null,
    deliveryupdate: null
};


export const fetchOrderListData = createAsyncThunk(
    "GetOrder",
    async (_, thunkAPI) => {
        try {
            const response = await TransactionService.getAllTransaction();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchDashboardInfo = createAsyncThunk(
    "fetchDashboardInfo",
    async (data:any, thunkAPI) => {
        try {
            const response = await TransactionService.getDashboardInfo(data);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchInvoiceInfo = createAsyncThunk(
    "fetchInvoiceInfo",
    async (ref:string, thunkAPI) => {
        try {
            const response = await TransactionService.getInvoiceInformation(ref);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateDeliveryInfo = createAsyncThunk(
    "updateDeliveryInfo",
    async (data:any, thunkAPI) => {
        try {
            const response = await TransactionService.updateDeliveryInfo(data.transactionOrderId, data);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fecthDeliveryInfo = createAsyncThunk(
    "fecthDeliveryInfo",
    async (id: number, thunkAPI) => {
        try {
            const response = await TransactionService.getDeliveryInfoList(id);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

const orderSlice: any = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //Order get--------->
            .addCase(fetchOrderListData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fetchOrderListData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.orderList = action.payload || [];
                }
            )
            .addCase(fetchOrderListData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })

            //profileInfo get--------->
            .addCase(fetchDashboardInfo.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fetchDashboardInfo.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.dashboard = action.payload;
                }
            )
            .addCase(fetchDashboardInfo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })

            .addCase(fetchInvoiceInfo.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fetchInvoiceInfo.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.invoiceInfo = action.payload;
                }
            )
            .addCase(fetchInvoiceInfo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })


            .addCase(updateDeliveryInfo.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                updateDeliveryInfo.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.deliveryupdate = action.payload;
                }
            )
            .addCase(updateDeliveryInfo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })


            .addCase(fecthDeliveryInfo.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fecthDeliveryInfo.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.deliveryList = action.payload || [];
                }
            )
            .addCase(fecthDeliveryInfo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to StaffPermissions";
            })

           
    },
});

export default orderSlice.reducer;
