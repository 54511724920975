import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import BranchService from "../../services/branch.service";

const postDataFailure = createAction<string>("Branch/postDataFailure");
const getDataFailure = createAction<string>("Branch/getDataFailure");
const deleteDataFailure = createAction<string>("Branch/deleteDataFailure");

export interface NewBranch {
    name: string;
    description: string;
};
interface ApiResponse {
    success: boolean;
    message: string;
    branchList?: NewBranch[];
};

type BranchApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    branchList: NewBranch[] | null;
};

const initialState: BranchApiState = {
    status: "idle",
    error: null,
    branchList: null,
};

export const addBranch = createAsyncThunk(
    "addBranch",
    async (data: NewBranch, thunkAPI) => {
        try {
            const response = await BranchService.addBranchData(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getBranch = createAsyncThunk(
    "getBranch",
    async (_, thunkAPI) => {
        try {
            const response = await BranchService.getBranchData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateBranch = createAsyncThunk(
    "updateBranch",
    async ({ id, data }: { id: any; data: NewBranch }, thunkAPI) => {
        try {
            const response = await BranchService.updateBranchData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteBranch = createAsyncThunk(
    "deleteBranch",
    async (id: any, thunkAPI) => {
        try {
            const response = await BranchService.deleteBranchData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const branchSlice: any = createSlice({
    name: "branch",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // post branch------------------->
            .addCase(addBranch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addBranch.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "contact Add failed";
            })

            // get branch------------------------->
            .addCase(getBranch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getBranch.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.branchList = action.payload || [];
                    }
                }
            )
            .addCase(getBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch contact data";
            })

            // update branch --------------------->
            .addCase(updateBranch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                updateBranch.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.branchList = action.payload || [];
                    }
                }
            )
            .addCase(updateBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update contact data";
            })

            //  delete branch --------------------------->
            .addCase(deleteBranch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete contact";
            })
    },
});

export default branchSlice.reducer;
