import React, { useState, useEffect } from "react";
import { fetchReportsData } from "../Services/reports.service"; // Ensure this path is correct
import { Line } from "react-chartjs-2"; // Using Chart.js for visualization
import 'chart.js/auto'; // Important for chart.js auto-importing necessary components
import Loading from "../../components/Loading/loading";

interface Report {
    date: string;
    sales: number;
}

const Reports: React.FC = () => {
    const [reports, setReports] = useState<Report[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [reportType, setReportType] = useState<string>('weekly');

    useEffect(() => {
        async function loadReports() {
            try {
                const data = await fetchReportsData();
                setReports(data);
            } catch (error) {
                console.error("Error fetching reports data", error);
            } finally {
                setLoading(false);
            }
        }

        loadReports();
    }, []);

    const filterReports = (data: Report[], type: string) => {
        // Mock implementation of filtering
        // Replace with actual logic to filter data based on the reportType
        switch (type) {
            case 'weekly':
                return data.slice(0, 7); // Mock logic for weekly
            case 'monthly':
                return data.slice(0, 30); // Mock logic for monthly
            case 'custom':
                return data; // Mock logic for custom
            default:
                return data;
        }
    };

    const filteredReports = filterReports(reports, reportType);

    const data = {
        labels: filteredReports.map(report => report.date),
        datasets: [
            {
                label: 'Sales',
                data: filteredReports.map(report => report.sales),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const handleReportTypeChange = (type: string) => {
        setReportType(type);
        setLoading(true);
        setTimeout(() => setLoading(false), 1000); // Simulate loading
    };

    const handleDownloadReport = () => {
        // Implement download functionality here
        alert(`Downloading ${reportType} report...`);
    };

    return (
        <div className="flex flex-col justify-start gap-3 items-start w-full">
            <div className="w-full flex-col justify-start items-start flex gap-1">
                <h1 className="text-2xl">Reports</h1>
                <p className="text-gray-400 text-sm">Analyze your sales data</p>
                <div className="flex gap-4 my-4">
                    <button
                        className={`py-2 px-4 rounded ${reportType === 'weekly' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => handleReportTypeChange('weekly')}
                    >
                        Weekly
                    </button>
                    <button
                        className={`py-2 px-4 rounded ${reportType === 'monthly' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => handleReportTypeChange('monthly')}
                    >
                        Monthly
                    </button>
                    <button
                        className={`py-2 px-4 rounded ${reportType === 'custom' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => handleReportTypeChange('custom')}
                    >
                        Custom
                    </button>
                </div>
                {loading ? (
                      <Loading></Loading>
                ) : (
                    <div className="w-full">
                        {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg pb-[50px]">
                            <Line data={data} />
                        </div> */}
                        <button
                            className="mt-4 py-2 px-4 bg-green-500 text-white rounded text-white"
                            onClick={handleDownloadReport}
                        >
                            Download {reportType.charAt(0).toUpperCase() + reportType.slice(1)} Report
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Reports;
