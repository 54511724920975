// PieChartComponent.tsx
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);
interface ServiceListProps {
  serviceList: any[];
}


const PieChartComponent: React.FC<ServiceListProps> = ({serviceList}) => {
  const [optionData, setData] = useState<any>(null);

  useEffect(() => {

    if (serviceList != null && serviceList.length > 0 ) {
      let option =  {
        labels: serviceList.map((x: any) => {return x.productName}),
        datasets: [
          {
            label: 'Total Quantity',
            data: serviceList.map((x: any) => {return x.totalQuantity}),
            backgroundColor: [
              '#083d6b',
              '#126AE8',
              '#add8e6',
              "#ff5722",
              "#607d8b"
            ],
            borderColor: [
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
            ],
            borderWidth: 1,
          },
        ],
        
      };

      setData(option);
    }else{   
      setData(null);
    }
  }, [serviceList]);
  return (
    <div className="w-full max-w-md mx-auto">
      {((serviceList!=null && serviceList.length > 0) && (optionData!=null)) &&
      <Pie data={optionData} />
      }
    </div>
  );
};

export default PieChartComponent;
