import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import getCurrentUserService from "../../services/getCurrentUser";

const getDataFailure = createAction<string>("Contact/getDataFailure");

export interface NewCurrentUser  {
    firstName: string;
    lastName: string;    
    email: string;
    phoneNumber: string;
    userRole:string;
    imageUrl:string;
};
interface ApiResponse {
    success: boolean;
    message: string;
    CurrentUserList?: NewCurrentUser[];
};

type ContactApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    CurrentUserList: NewCurrentUser[] | null;
};

const initialState: ContactApiState = {
    status: "idle",
    error: null,
    CurrentUserList: null,
};



export const getCurrentUser = createAsyncThunk(
    "getCurrentUser",
    async (_, thunkAPI) => {
        try {
            const response = await getCurrentUserService.getCurrentUserData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

const currentUserSlice:any = createSlice({
    name: "currentUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

// get User------------------------->
            .addCase(getCurrentUser.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getCurrentUser.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if(action.payload != null ){
                    state.CurrentUserList = action.payload || [];
                    }
                }
            )
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch contact data";
            })

    },
});

export default currentUserSlice.reducer;
