import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addCalendarAppointment = (data:any) => {
  return axiosInstance.post("appointment", data);
};

const getCalendarAppointmentData = async () => {
  try {
    const response = await axiosInstance.get("appointment");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateCalendarAppointmentData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`appointment/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteCalendarAppointmentData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`appointment/${id}`);
    return response;
  }catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const CalendarService = {
    addCalendarAppointment,
    getCalendarAppointmentData,
    updateCalendarAppointmentData,
    deleteCalendarAppointmentData
}

export default CalendarService;