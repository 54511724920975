import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "../store/model/jwtPayload";
export const  LocalStorageService = {

      setItem(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
      },
    
      // Method to get an item from localStorage
      getItem<T>(key: string): T | null {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
        // console.log(item);
      },
    
      // Method to remove an item from localStorage
      removeItem(key: string): void {
        localStorage.removeItem(key);
      },
    
      // Method to clear all items from localStorage
      clear(): void {
        localStorage.clear();
      },
      getTenant(key: string){
        let response = null;
        const item = localStorage.getItem(key);
        if(item != null){
          let data = JSON.parse(item);
          if(data){
            const decoded = jwtDecode<JwtPayload>(data.token); 
            response = decoded.tenant;
            // console.log(decoded);
          }
        }
        return response;
      }
  }