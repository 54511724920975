import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import BookingServicesService from "../../services/bookingServices.service";

const postDataFailure = createAction<string>("Bookingservice/postDataFailure");
const getDataFailure = createAction<string>("Bookingservice/getDataFailure");
const deleteDataFailure = createAction<string>("BookingService/deleteDataFailure");

type NewBookingService = {
    imageUrl: string;
    category: string;
    serviceName: string;
    tagLine: string;
    description: string;
    //BufferTime: string;
    pricingPlanId: string;
    pricingtype: string;
    amount: number;
    paymentReference: string;
    location: string;
    addreess: string;
    policyUrl: string;
    servicetypeTyId: number;
    serviceDuration: string;
    isOnlineBooking: boolean;
    staffServices: staffServices[];
    ServiceBranches: ServiceBranches[];
    // Online bookings
};
export interface staffServices {
    serviceId: string
    staffId: string

}

export interface ServiceBranches {
    serviceId: string
    branchId: string

}

interface ApiResponse {
    success: boolean;
    message: string;
    bookingServiceList?: NewBookingService[];
};

type bookingServiceApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    bookingServiceList: NewBookingService[] | null;
};

const initialState: bookingServiceApiState = {
    status: "idle",
    error: null,
    bookingServiceList: null,
};

export const addBooking = createAsyncThunk(
    "booking",
    async (data: any, thunkAPI) => {
        try {
            const response = await BookingServicesService.addBooking(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getBookingServiceData = createAsyncThunk(
    "getBookingServiceData",
    async (_, thunkAPI) => {
        try {
            const response = await BookingServicesService.getBookingServiceData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const putBookingServiceData = createAsyncThunk(
    "putBookingServiceData",
    async ({ id, data }: { id: any; data: NewBookingService }, thunkAPI) => {
        try {
            const response = await BookingServicesService.updateBookingServiceData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteBookingServiceData = createAsyncThunk(
    "deleteBookingServiceData",
    async (id: any, thunkAPI) => {
        try {
            const response = await BookingServicesService.deleteBookingServiceData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const bookingServiceSlice: any = createSlice({
    name: "bookingService",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addBooking.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addBooking.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addBooking.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "bookingService Add failed";
            })

            //bookingService get--------->
            .addCase(getBookingServiceData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getBookingServiceData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.bookingServiceList = action.payload || [];
                }
            )
            .addCase(getBookingServiceData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch bookingService data";
            })
            //  bookingSerice update------------------------------>
            .addCase(putBookingServiceData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                putBookingServiceData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                       // state.bookingServiceList = action.payload || [];
                    }
                }
            )
            .addCase(putBookingServiceData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update contact data";
            })
            // bookingService Delete------------------------------>
            .addCase(deleteBookingServiceData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteBookingServiceData.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteBookingServiceData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete contact";
            })
    },
});

export default bookingServiceSlice.reducer;
