import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import BookingServicePricePlan from "../../services/bookingServicePricePlan.service";

//const postDataFailure = createAction<string>("Bookingservice/postDataFailure");
// const getDataFailure = createAction<string>("BookingServiceType/getDataFailure");

type NewBookingService = {
    name: string;
    desscription: string;
};

interface ApiResponse {
    success: boolean;
    message: string;
    bookingServicePricePlanList?: NewBookingService[];
};

type bookingServiceApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    bookingServicePricePlanList: NewBookingService[] | null;
};

const initialState: bookingServiceApiState = {
    status: "idle",
    error: null,
    bookingServicePricePlanList: null,
};

// export const addBooking = createAsyncThunk(
//     "booking",
//     async (data: NewBookingService, thunkAPI) => {
//         try {
//             const response = await BookingServicesService.addBooking(data);
//             const resData = response.data;
//             return resData;
//         } catch (error: any) {
//             const message =
//                 (error.response &&
//                     error.response.data &&
//                     error.response.data.message) ||
//                 error.message ||
//                 error.toString();
//             thunkAPI.dispatch(postDataFailure(message));
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

export const getBookingServicePricePlanData = createAsyncThunk(
    "getBookingServicePricePlan",
    async (_, thunkAPI) => {
        try {
            const response = await BookingServicePricePlan.getBookingServicePricePlan();
            //console.log(response.data);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const BookingServicePricePlanSlice:any = createSlice({
    name: "PricePlan",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // .addCase(addBooking.pending, (state) => {
            //     state.status = "loading";
            //     state.error = null;
            // })
            // .addCase(
            //     addBooking.fulfilled,
            //     (state, action: PayloadAction<ApiResponse>) => {
            //         state.status = "idle";
            //     }
            // )
            // .addCase(addBooking.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.error.message || "bookingService Add failed";
            // })

//bookingService get--------->
            .addCase(getBookingServicePricePlanData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getBookingServicePricePlanData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.bookingServicePricePlanList = action.payload || [];
                }
            )
            .addCase(getBookingServicePricePlanData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch BookingServicePricePlan data";
            })
    },
});

export default BookingServicePricePlanSlice.reducer;
