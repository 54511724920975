import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addServiceType = (data: any) => {
  return axiosInstance.post("BookingServiceType", data);
};

const getBookingServiceType = async () => {
  try {
    const response = await axiosInstance.get("BookingServiceType");
    //console.log(response);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateBookingServiceType = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`BookingServiceType/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const BookingServiceType = {
  addServiceType,
  getBookingServiceType,
  updateBookingServiceType,
}

export default BookingServiceType;