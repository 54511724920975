import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import CalendarService from "../../services/calendar.service";

const postDataFailure = createAction<string>("Calendar/postDataFailure");
const getDataFailure = createAction<string>("Calendar/getDataFailure");
const deleteDataFailure = createAction<String>("Calendar/deleteDataFailure");

type NewAppointment = {
    email: string;
    phoneNumber: string;
    appointmentDate: Date;
    startTime: string;
    endTime: string;
    location: string;
    // scheduleType: string;
    appointmentContacts: appointmentContacts[];
    appointmentServices: appointmentServices[];
    appointmentStaffInfos: appointmentStaffInfos[];
};
export interface appointmentContacts {
    appointmentId: string;
    contactId: string;
}

export interface appointmentServices {
    appointmentId: string;
    serviceId: string;
}

export interface appointmentStaffInfos {
    appointmentId: string;
    staffId: string;
}

interface ApiResponse {
    success: boolean;
    message: string;
    appointmentList?: NewAppointment[];
};

type CalendarApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    appointmentList: NewAppointment[] | null;
};

const initialState: CalendarApiState = {
    status: "idle",
    error: null,
    appointmentList: null,
};

export const addCalendarAppointment = createAsyncThunk(
    "addCalendarAppointment",
    async (data: NewAppointment, thunkAPI) => {
        try {
            const response = await CalendarService.addCalendarAppointment(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getCalendarAppointmentData = createAsyncThunk(
    "getCalendarAppointmentData",
    async (_, thunkAPI) => {
        try {
            const response = await CalendarService.getCalendarAppointmentData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const putCalendarAppointmentData = createAsyncThunk(
    "putCalendarAppointmentData",
    async ({ id, data }: { id: any; data: NewAppointment }, thunkAPI) => {
        try {
            const response = await CalendarService.updateCalendarAppointmentData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteCalendarAppointmentData = createAsyncThunk(
    "deleteCalendarAppointmentData",
    async (id: any, thunkAPI) => {
        try {
            const response = await CalendarService.deleteCalendarAppointmentData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const calendarSlice: any = createSlice({
    name: "calendar",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addCalendarAppointment.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addCalendarAppointment.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addCalendarAppointment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "CalendarAppointment Add failed";
            })

            //CalendarAppointmentData get--------->

            .addCase(getCalendarAppointmentData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getCalendarAppointmentData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.appointmentList = action.payload || [];
                }
            )
            .addCase(getCalendarAppointmentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch CalendarAppointment data";
            })

            // CalendarAppointmentData update----------------------->
            .addCase(putCalendarAppointmentData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                putCalendarAppointmentData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.appointmentList = action.payload || [];
                    }
                }
            )
            .addCase(putCalendarAppointmentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update contact data";
            })
            // CalendarAppointmentData Delete------------->
            .addCase(deleteCalendarAppointmentData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteCalendarAppointmentData.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteCalendarAppointmentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete contact";
            })
    },
});

export default calendarSlice.reducer;
