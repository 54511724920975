import { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../components/Dropdown";
import { AddRole, AddStaff, DeleteStaff, HeaderButton,  StaffDrawer } from "../../components";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { CiFilter } from "react-icons/ci";
import { fetchStaffData } from "../../store/slice/staff-slice";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaEye } from "react-icons/fa";


const Staff = () => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const { staffList } = useSelector((state: any) => state.staff);
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = 5;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [viewStaffId, setViewStaffId] = useState(null);
    const [delId, setDelId] = useState(null);
    const [Staff, setStaff] = useState<any[]>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openDelModal, setOpenDelModal] = useState(false);
    const today = new Date();

    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });
    const toggleAddModal = () => {
        setOpenModal(!openModal);
    };
    const toggleUpdateModal = () => {
        setOpenUpdateModal(!openUpdateModal);
    };
    const toggleDeleteModal = () => {
        setOpenDelModal(!openDelModal);
    };
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(fetchStaffData());
        }
        return () => { effectSeviceCall.current = true; };
    }, [dispatch]);

    const pageCount = staffList ? Math.ceil(staffList.length / perPage) : 0;
    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };

    const startIndex = currentPage * perPage;
    const endIndex = startIndex + perPage;

    const paginatedStaffList = staffList ? staffList.slice(startIndex, endIndex) : [];

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target?.value || '';
        setSearchTerm(newValue);
    };
    const filteredStaffList = paginatedStaffList.filter((staff: any) => {
        const { firstName, lastName, phoneNumber, address, id } = staff;
        const searchValue = searchTerm.toLowerCase();
        const idString = typeof id === 'string' ? id : String(id);
        return (
            (firstName && firstName.toLowerCase().includes(searchValue)) ||
            (lastName && lastName.toLowerCase().includes(searchValue)) ||
            (phoneNumber && phoneNumber.includes(searchValue)) ||
            (address && address.toLowerCase().includes(searchValue)) ||
            (idString && idString.toLowerCase().includes(searchValue))
        );

    });

    return (
        <>
            <div className="flex flex-col justify-start gap-3 items-start w-full">
                <div className="w-full flex-col justify-start items-start flex gap-1">
                    <h1 className="text-2xl">Staffs</h1>
                    <p className="text-gray-400 text-sm">{formattedDate}</p>
                    <div className="flex fles-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5 ">
                        <div className="flex fles-row gap-2" ref={dropdownRef}>
                            <div className="flex gap-3 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md hover:bg-gray-100 placeholder-text-sm">
                                <div className="relative">
                                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="text" className="block ps-8 text-sm border text-gray-900 rounded-md h-10" placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchTermChange} />
                                </div>
                            </div>
                            <button onClick={() => setIsOpen(!isOpen)} type="button" className="flex gap-2 p-2 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 hover:bg-gray-100">
                                <div className="fill-black">
                                    <CiFilter className="w-6 h-6" />
                                </div>
                                <p className="mt-0.5">Filters</p>
                            </button>
                            {isOpen && (
                                <div className=" left-[250px]  md:w-32 md:end-0  z-50 border absolute mt-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 bg-white-full">
                                    <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="">
                            <HeaderButton buttonName="Staff" isAdd={true} onClickAdd={toggleAddModal}></HeaderButton>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="">
                        <table className="w-full border overflow-y-auto text-sm text-left rounded-md rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs rounded-md border text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-green-dark bg-gray-100  rounded focus:ring-green-dark dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Staff Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStaffList?.map((staff: any) => (
                                    <tr key={staff.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-table-search-3" type="checkbox" className="w-4 h-4 text-green-dark bg-gray-100  rounded focus:ring-green-dark dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                                            <div className="flex ">
                                                <img className="w-10 h-10 rounded-full" src={staff.image ? staff.image : "https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"} alt="Jese image" />
                                                <div className="ps-3">
                                                    <div className="text-base font-semibold">{staff.firstName}  {staff.lastName}</div>
                                                    <div className="font-normal text-gray-500">{staff.email}</div>
                                                </div>
                                            </div>
                                        </th>
                                        <td className="px-6 py-4">
                                            {staff.phoneNumber || "Phone Number is Not Available"}
                                        </td>
                                        <td className="flex items-center px-6 py-4">
                                            <div className="flex flex-row items-start justify-start gap-2">
                                                <button
                                                    className="bg-transparent p-2 border-2 rounded-md w-auto text-green-dark"
                                                    type="button"
                                                    onClick={() => {setStaff(staff); toggleUpdateModal(); }}
                                                >
                                                    <FaRegEdit className="w-5 h-5" />
                                                </button>
                                                <button
                                                    className="bg-transparent p-2 border-2 rounded-md w-auto text-red-600 "
                                                    type="button"
                                                    onClick={() => { setDelId(staff.id); toggleDeleteModal(); }}
                                                >
                                                    <MdDeleteOutline className="w-5 h-5"/>
                                                </button>
                                                <button
                                                    className="bg-transparent p-2  border-2 rounded-md w-auto text-black "
                                                    type="button"
                                                    onClick={() => { setViewStaffId(staff.id); setOpenDrawer(!openDrawer); }}
                                                >
                                                    <FaEye className="w-5 h-5"/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {filteredStaffList.length === 0 && (
                            <div className="bg-white border border-red-200 border-solid flex  flex-col h-auto items-center justify-center mt-1 p-4 rounded-md w-full">
                                <p className="text-red-800">No Staff found.</p>
                            </div>
                        )}
                        <AddStaff openDrawer={openModal} Close={toggleAddModal} modalType="Add" />
                        <AddStaff openDrawer={openUpdateModal} Close={toggleUpdateModal} modalType="Edit" staff={Staff} />
                        <DeleteStaff openDelModal={openDelModal} delId={delId} Close={toggleDeleteModal} />
                        <StaffDrawer openDrawer={openDrawer} staffId={viewStaffId} onClose={toggleDrawer} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Staff;