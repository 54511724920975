import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";
const getCurrentUserData = async () => {
  try {
    const response = await axiosInstance.get("users");
    return response;
  }catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

// const updateCurrentUserData = async (id: any, data: any) => {
//   try {
//     const response = await axiosInstance.put(`Staff/${id}`, data);
//     return response;
//   } catch (error: any) {
//     throw new Error(
//       error.response?.data?.message || error.message || "Failed to update staff data"
//     );
//   }
// };

// const deleteCurrentUserData = async (id: any) => {
//   try {
//     const response = await axiosInstance.delete(`Staff/${id}`);
//     return response;
//   } catch (error: any) {
//     throw new Error(
//       error.response?.data?.message || error.message || "Failed to delete Staff data"
//     );
//   }
// };

const getCurrentUserService = {
    // postStaffData,
    getCurrentUserData,
    // updateStaffData,
    // deleteStaffData
}

export default getCurrentUserService;