import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slice/auth-slice';
import staffSlice from './slice/staff-slice';
import roleSlice from './slice/role-slice';
import contactSlice from './slice/contact-slice';
import bookingServiceTypeSlice from './slice/bookingServiceType-Slice';
import BookingServicePricePlanSlice from './slice/bookingServicePricePlan-Slice';
import bookingServiceSlice from './slice/bookingservice-slice';
import calendarSlice from './slice/calendar-slice';
import currentUserSlice from './slice/getCurrentUser-slice';
import branchSlice from './slice/branch-slice';
import orderSlice from './slice/order-slice';
import locationSlice from './slice/location-slice';


const store = configureStore({
    reducer: {
        user: authSlice,
        staff: staffSlice,
        role: roleSlice,
        contact: contactSlice,
        serviceType: bookingServiceTypeSlice,
        ServicePricePlan: BookingServicePricePlanSlice,
        bookingService: bookingServiceSlice,
        appointment: calendarSlice,
        currentUser: currentUserSlice,
        branch: branchSlice,
        order: orderSlice,
        location:locationSlice

    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
