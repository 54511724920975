
const getWeekStartAndEnd = (date: Date): { start: Date; end: Date } => {
    const start = new Date(date);
    const end = new Date(date);
  
    // Set start to the start of the week (Sunday)
    start.setDate(date.getDate() - date.getDay());
    start.setHours(0, 0, 0, 0);
  
    // Set end to the end of the week (Saturday)
    end.setDate(date.getDate() + (6 - date.getDay()));
    end.setHours(23, 59, 59, 999);
  
    return { start, end };
  };
  
  const getLastWeekStartAndEnd = (date: Date): { start: Date; end: Date } => {
    const currentStart = new Date(date);
    const currentEnd = new Date(date);
  
    // Calculate the start of the current week
    currentStart.setDate(date.getDate() - date.getDay());
    currentStart.setHours(0, 0, 0, 0);
  
    // Calculate the end of the current week
    currentEnd.setDate(date.getDate() + (6 - date.getDay()));
    currentEnd.setHours(23, 59, 59, 999);
  
    // Adjust to get last week
    const lastWeekStart = new Date(currentStart);
    const lastWeekEnd = new Date(currentEnd);
  
    // Subtract 7 days to get the last week
    lastWeekStart.setDate(currentStart.getDate() - 7);
    lastWeekEnd.setDate(currentEnd.getDate() - 7);
  
    return { start: lastWeekStart, end: lastWeekEnd };
  };
  
  const getMonthStartAndEnd = (date: Date): { start: Date; end: Date } => {
    const start = new Date(date);
    const end = new Date(date);
  
    // Set start to the first day of the month
    start.setDate(1);
    start.setHours(0, 0, 0, 0);
  
    // Set end to the last day of the month
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    end.setHours(23, 59, 59, 999);
  
    return { start, end };
  };
  
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const addDays = (days: number) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  const CommonDateService = {   
    addDays,
    formatDate,
    getMonthStartAndEnd,
    getLastWeekStartAndEnd,
    getWeekStartAndEnd
}

export default CommonDateService;