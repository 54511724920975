export const fetchReportsData = async () => {
    // Mock data
    return [
        { date: "2024-07-01", sales: 150 },
        { date: "2024-07-02", sales: 200 },
        { date: "2024-07-03", sales: 250 },
        { date: "2024-07-04", sales: 300 },
        { date: "2024-07-05", sales: 350 },
        { date: "2024-07-06", sales: 400 },
        { date: "2024-07-07", sales: 450 },
    ];
};
