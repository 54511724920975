import React, { useState } from "react";

const ProfilePage: React.FC = () => {
  // State for Profile Picture
  const [profilePic, setProfilePic] = useState<string>("https://via.placeholder.com/150/0000FF/808080?Text=A");
  const [imageFile, setImageFile] = useState<File | null>(null);

  // State for Profile Info (Editable)
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [fullName, setFullName] = useState("Super Admin");
  const [userName, setUserName] = useState("superadmin");
  const [email, setEmail] = useState("superadmin@email.com");
  const [phone, setPhone] = useState("+91 8317493452");

  // State for Change Password Section
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState<string | null>(null);

  // Handle Profile Picture Upload
  const handleProfilePicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setProfilePic(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle Profile Info Editing
  const handleProfileEdit = () => {
    setIsEditingProfile(!isEditingProfile);
  };

  const handleProfileSave = () => {
    setIsEditingProfile(false);
    // Save profile changes logic (e.g., API call)
  };

  // Handle Password Change
  const handlePasswordChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordMessage("Passwords do not match.");
      return;
    }
    if (newPassword.length < 6) {
      setPasswordMessage("Password must be at least 6 characters.");
      return;
    }

    // Simulate password update success
    setPasswordMessage("Password updated successfully.");
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Header Section */}
      <h1 className="text-2xl font-bold text-gray-800 mb-4">My Profile</h1>
      <div className="text-sm text-gray-500 mb-6">
        {/* <span className="text-blue-600">Home</span> &gt; My Profile */}
      </div>

      {/* Profile Picture Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Profile Picture</h2>
        <p className="text-gray-500 mb-4">This is how others will recognize you</p>
        <div className="flex items-center justify-center">
          <div className="relative">
            <img
              className="w-24 h-24 rounded-full border border-gray-300"
              src={profilePic} // Dynamic profile picture
              alt="Profile"
            />
            <label
              htmlFor="profilePicture"
              className="absolute bottom-0 right-0 bg-blue-600 text-white p-2 rounded-full cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M17.414 2.586a2 2 0 010 2.828l-9.172 9.172a2 2 0 01-.878.506l-4 1a1 1 0 01-1.213-1.213l1-4a2 2 0 01.506-.878l9.172-9.172a2 2 0 012.828 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M16 4l-2-2 2 2zm-4 4l-2-2 2 2zm-6 8l2 2-2-2zm2 2l2-2-2 2z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <input
              id="profilePicture"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleProfilePicChange}
            />
          </div>
        </div>
      </div>

      {/* Profile Info Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Profile Info</h2>
        <p className="text-gray-500 mb-4">Others deserve to know you more</p>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">Full Name *</label>
            {isEditingProfile ? (
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ) : (
              <p className="text-gray-600">{fullName}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">User Name *</label>
            {isEditingProfile ? (
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ) : (
              <p className="text-gray-600">{userName}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">Email *</label>
            {isEditingProfile ? (
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ) : (
              <p className="text-gray-600">{email}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">Phone *</label>
            {isEditingProfile ? (
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ) : (
              <p className="text-gray-600">{phone}</p>
            )}
          </div>
        </div>

        {/* Save/Cancel Editing */}
        {isEditingProfile ? (
          <div className="flex justify-end gap-4 mt-4">
            <button
              onClick={handleProfileSave}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
            >
              Save Changes
            </button>
            <button
              onClick={handleProfileEdit}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg shadow hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={handleProfileEdit}
            className="px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600 mt-4"
          >
            Edit Profile
          </button>
        )}
      </div>

      {/* Change Password Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Change Password</h2>
        <p className="text-gray-500 mb-4">For improved account security</p>
        <form onSubmit={handlePasswordChange} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">Old Password *</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">New Password *</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">Confirm Password *</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              required
            />
          </div>
          {passwordMessage && (
            <p
              className={`text-sm ${
                passwordMessage === "Password updated successfully."
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {passwordMessage}
            </p>
          )}
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
