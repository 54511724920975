import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { addBranch, updateBranch, getBranch } from '../../../store/slice/branch-slice';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";

function AddBranch({ openDrawer, Close, modalType, branch }: { openDrawer: boolean, Close: any, modalType: string, branch?: any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Branch' : 'Edit Branch'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingBranch, setExistingBranch] = useState(branch);

console.log(branch);
    const validationSchema = object().shape({
        name: string()
            .required('Branch Name is required'),
        description: string()
            .required('Description is required'),

    });
    const {
        register,
        handleSubmit,
        reset,
        // setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        // setIsLoading(true);
        setTimeout(() => {
            // setIsLoading(false);
        }, 300);
        if (modalType === 'Add') {
            console.log(JSON.stringify(data, null, 2));
            dispatch(addBranch(data))
                .unwrap()
                .then(() => {
                    dispatch(getBranch());
                    reset();
                    Close();
                    navigate("/settings/branch");

                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                        // setIsLoading(false);
                    }, 300);
                });
        } else {
            
            console.log(JSON.stringify(data, null, 2));
            dispatch(updateBranch({ id: branch.id, data }))
                .unwrap()
                .then(() => {
                    dispatch(getBranch());
                    Close();
                    navigate("/settings/branch");

                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                        // setIsLoading(false);
                    }, 300);
                });
        }

    };

    useEffect(() => {
        if (modalType === 'Edit' && branch) {
            setExistingBranch(branch);
            reset(branch);
        }
    }, [branch, modalType, reset]);


    return (
        <Modal
            className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => {Close(); reset();}}
            initialFocus={emailInputRef}
            size="lg"
        >
            <Modal.Header className="bg-white-full p-5">
                <span className="text-blue_gray-900 text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-full">
                <div className="space-y-5 w-full">
                    <div className='p-5'>
                        <form className="mx-auto" onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-3 max-w-lg">
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Branch Name
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.name ? 'is-invalid' : ''} `}
                                        placeholder="Enter Branch Name"
                                        {...register('name')}
                                    />
                                    <div className="font-medium text-left mt-2 text-xs text-red-600" >{errors?.name?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Description
                                    </label>
                                    <textarea
                                        className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                        {...register('description')}
                                        placeholder="Describe this Branch"
                                    ></textarea>
                                    <div className="font-medium text-left mt-2 text-xs text-red-600" >{errors?.description?.message?.toString()}</div>
                                </div>
                                <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                                    <div className="flex flex-row gap-2 items-end justify-end w-full">
                                        <button onClick={() => {
                                            reset();
                                            Close();
                                        }} type="button" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border border-gray-200">
                                            <p>Cancel</p>
                                        </button>
                                        <button type="submit" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                            <p>Save</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { AddBranch };
