import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/hooks/redux-hooks';
import { getCalendarAppointmentData } from "../../store/slice/calendar-slice";

const localizer = momentLocalizer(moment);


const BigCalendar: React.FC = () => {
    const dispatch = useAppDispatch();
    const { appointmentList } = useSelector((state: any) => state.appointment);
    // const history = useHistory();

    useEffect(() => {
        dispatch(getCalendarAppointmentData());
    }, []);

    let sortedEvents: any[] = [];

    if (appointmentList) {
        sortedEvents = appointmentList.map((appointment: any) => {
            const startParts = appointment.startTime.split(":");
            const endParts = appointment.endTime.split(":");

            const startDate = new Date(appointment.appointmentDate);
            startDate.setHours(parseInt(startParts[0]), parseInt(startParts[1]));

            const endDate = new Date(appointment.appointmentDate);
            endDate.setHours(parseInt(endParts[0]), parseInt(endParts[1]));

            return {
                title: appointment.email,
                start: startDate,
                end: endDate,
                id: appointment.id,
            };
        });

        // console.log(sortedEvents);
        sortedEvents.sort((a, b) => a.start.getTime() - b.start.getTime());
    }

    // const handleEventClick = (event: any) => {
    //     history.push(`/serviceUpdate/${event.id}`);
    // };

    return (
        <div className=' overflow-auto border-none'>
            <div style={{ width: '100%' }} className='h-[800px]'>
                <Calendar
                    className='w-full h-auto'
                    localizer={localizer}
                    events={sortedEvents}
                    startAccessor="start"
                    // onSelectEvent={handleEventClick}
                    endAccessor="end"
                />
            </div>
        </div>
    );
};

export { BigCalendar };

