import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(isOpen:boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);


  useEffect (() => {
    setIsComponentVisible(isOpen);
  })

  const handleClickOutside = (event:any) => {
    console.log(ref.current);
   // if (ref.current && !ref.current?.contains(event.target)) {
      setIsComponentVisible(!isComponentVisible);
    //}
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, !isComponentVisible);

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside,
        !isComponentVisible
      );
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}