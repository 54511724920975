import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addBranchData = (data: any) => {
  return axiosInstance.post("Branch", data);
};
const getBranchData = async () => {
  try {
    const response = await axiosInstance.get("Branch");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateBranchData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`Branch/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteBranchData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`Branch/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const BranchService = {   
    getBranchData,
    addBranchData,
    updateBranchData,
    deleteBranchData
}

export default BranchService;