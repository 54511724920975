import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const addBooking = (data:any) => {
  return axiosInstance.post("BookingService", data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const getBookingServiceData = async () => {
  try {
    const response = await axiosInstance.get("BookingService");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateBookingServiceData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`BookingService/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteBookingServiceData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`BookingService/${id}`);
    return response;
  }catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const BookingServicesService = {
    addBooking,
    getBookingServiceData,
    updateBookingServiceData,
    deleteBookingServiceData
}

export default BookingServicesService;