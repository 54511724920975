import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchContactData, deleteContact } from '../../../store/slice/contact-slice';

function DeleteContact({ openDelModal, delId, Close }: { openDelModal: boolean, delId: any, Close: any }) {
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        dispatch(deleteContact(delId))
            .unwrap()
            .then(() => {
                Close();
                dispatch(fetchContactData());
            })
            .catch((error) => {
                console.error("Failed to delete Contact: ", error);
            })
            .finally(() => {
            });
    };

    return (
        <Modal className='backdrop-blur-sm backdrop-brightness-75 p-7 shadow-sm' show={openDelModal} size="md" onClose={Close} popup>
            <Modal.Header className='bg-white-full' />
            <Modal.Body className='bg-white-full'>
                <div className="text-center py-2">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500">
                        Are you sure you want to delete this Contact?
                    </h3>
                    <div className="flex justify-between gap-4 px-5 py-3">
                        <button
                            type="button"
                            className="flex gap-3 p-2  text-sm font-medium text-red-600 bg-transparent rounded-md border-2 hover:border-black "
                            onClick={handleDelete}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="flex gap-3 p-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                            onClick={Close}
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { DeleteContact };
