import { Link } from "react-router-dom";
import { MdAddCircleOutline } from "react-icons/md";


function HeaderButton({ buttonName, isAdd = false, onClickAdd, link }: { buttonName?: string, isAdd?: boolean, onClickAdd?: any, link?: any }) {


    return (
        <>
            {(isAdd) && (link ? (
                <Link to={`${link}`}>
                    <button type="button" className="flex gap-2 py-1 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange" onClick={onClickAdd}>
                        <MdAddCircleOutline className="h-6 w-6" />
                        <p className="mt-0.5">{buttonName}</p>
                    </button>
                </Link>
            ) : (
                <button type="button" className="flex gap-2 px-4 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange" onClick={onClickAdd}>
                    <MdAddCircleOutline className="h-6 w-6" />
                    <p className="mt-0.5">{buttonName}</p>
                </button>
            ))
            }
        </>
    )
}

export { HeaderButton };