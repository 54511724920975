import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import StaffService from "../../services/staff.service";

const postDataFailure = createAction<string>("staff/postDataFailure");
const getDataFailure = createAction<string>("staff/getDataFailure");
const deleteDataFailure = createAction<string>("staff/deleteDataFailure");

type NewStaff = {
    firstName: string;
    lastName: string;
    email: string;
    PhoneNumber: string;
    RoleName: string;
    description: string;
    staffBranches: staffBranches[];
};
export interface staffBranches {
    branchId: string;
    staffId: string;
}

type StaffAccess = {
    staffId: any;
    IsFullAccess: boolean;
};
interface ApiResponse {
    success: boolean;
    message: string;
    staffList?: NewStaff[];
    staffAccessList?: StaffAccess[];
    staffeGrantPermissions: [];
    profileInfos: any;
};

type StaffApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    staffList: NewStaff[] | null;
    staffAccessList: StaffAccess[] | null;
    staffeGrantPermissions: [] | null;
    profileInfos: any;
};

const initialState: StaffApiState = {
    status: "idle",
    error: null,
    staffList: null,
    staffAccessList: null,
    staffeGrantPermissions: null,
    profileInfos: null,
};

export const addStaff = createAsyncThunk(
    "AddStaff",
    async (data: NewStaff, thunkAPI) => {
        try {
            const response = await StaffService.postStaffData(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchStaffData = createAsyncThunk(
    "GetStaff",
    async (_, thunkAPI) => {
        try {
            const response = await StaffService.getStaffData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getStaffProfileData = createAsyncThunk(
    "getStaffProfileData",
    async (_, thunkAPI) => {
        try {
            const response = await StaffService.getStaffProfile();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getStaffGrantPermissions = createAsyncThunk(
    "getStaffGrantPermissions",
    async (id: number, thunkAPI) => {
        try {
            const response = await StaffService.getStaffGrantPermissionsData(id);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const postStaffeGrantPermissions = createAsyncThunk(
    "postStaffeGrantPermissions",
    async ({ data }: { data: StaffAccess }, thunkAPI) => {
        try {
            const response = await StaffService.postStaffeGrantPermissionsData(data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const putStaffData = createAsyncThunk(
    "putStaffData",
    async ({ id, data }: { id: any; data: NewStaff }, thunkAPI) => {
        try {
            const response = await StaffService.updateStaffData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteStaff = createAsyncThunk(
    "deleteStaff",
    async (id: any, thunkAPI) => {
        try {
            const response = await StaffService.deleteStaffData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const staffSlice: any = createSlice({
    name: "staff",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addStaff.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addStaff.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addStaff.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Staff Add failed";
            })



            //staff get--------->
            .addCase(fetchStaffData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                fetchStaffData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.staffList = action.payload || [];
                }
            )
            .addCase(fetchStaffData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })

            //profileInfo get--------->
            .addCase(getStaffProfileData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getStaffProfileData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.profileInfos = action.payload;
                }
            )
            .addCase(getStaffProfileData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch staff data";
            })

            //staffeGrantPermissions get--------->
            .addCase(getStaffGrantPermissions.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getStaffGrantPermissions.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.staffeGrantPermissions = action.payload || [];
                }
            )
            .addCase(getStaffGrantPermissions.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to StaffPermissions";
            })

            // staff update---------------->
            .addCase(putStaffData.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                putStaffData.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.staffList = action.payload || [];
                    }
                }
            )
            .addCase(putStaffData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update Staff data";
            })

            // postStaffAccess---------------->
            .addCase(postStaffeGrantPermissions.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                postStaffeGrantPermissions.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.staffAccessList = action.payload || [];
                    }
                }
            )
            .addCase(postStaffeGrantPermissions.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update Staff data";
            })

            // staff delete------------------------------->

            .addCase(deleteStaff.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteStaff.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteStaff.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete Staff";
            })
    },
});

export default staffSlice.reducer;
