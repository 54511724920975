import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, date } from 'yup';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { getBranch } from '../../../store/slice/branch-slice';
import { useSelector } from 'react-redux';
import { addStaff, fetchStaffData, putStaffData } from '../../../store/slice/staff-slice';
import { SelectBox } from '../../SelectBox';
import { getRole } from '../../../store/slice/role-slice';
import { getBookingServiceData } from '../../../store/slice/bookingservice-slice';
import { fetchContactData } from '../../../store/slice/contact-slice';
import { addCalendarAppointment } from '../../../store/slice/calendar-slice';

function AddAppointment({ openDrawer, Close, modalType, appointment }: { openDrawer: boolean, Close: any, modalType: string, appointment?: any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Appointent' : 'Edit Appointent'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [openModalSecond, setOpenModalSecond] = useState(false);
    const [openModalThird, setOpenModalThird] = useState(false);
    const [openModalForth, setOpenModalForth] = useState(false);
    const [modalPlacementSecond, setModalPlacementSecond] = useState('center');
    const [modalPlacementThird, setModalPlacementThird] = useState('center');
    const [modalPlacementForth, setModalPlacementForth] = useState('center');
    const { contactList } = useSelector((state: any) => state.contact);
    const { branchList } = useSelector((state: any) => state.branch);
    const { staffList } = useSelector((state: any) => state.staff);
    const { bookingServiceList } = useSelector((state: any) => state.bookingService);
    const [searchTerm, setSearchTerm] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [endTime, setEndTime] = useState('');
    const [errorMessageContact, setErrorMessageContact] = useState<string>('');
    const [errorMessageBranch, setErrorMessageBranch] = useState<string>('');
    const [errorMessageStaff, setErrorMessageStaff] = useState<string>('');
    const [existingAppointment, setExistingAppointment] = useState(appointment);
    const [contactData, setcontactData] = useState<any>();
    const [staffData, setStaffData] = useState<any>();
    const [seletedContacts, setSelectedContacts] = useState<any[]>([]);
    const [seletedBranch, setSelectedBranch] = useState<any[]>([]);
    const [seletedStaff, setSelectedStaff] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();



    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(getBookingServiceData());
            dispatch(fetchContactData());
            dispatch(getBranch());
            dispatch(fetchStaffData());
        }
        return () => { effectSeviceCall.current = true; };
    }, [dispatch]);

    const StaffOptionsLists = staffList ? staffList.map((staff: any) => ({
        label: staff.firstName + " " + staff.lastName,
        value: staff.id
    })) : [];

    const ContactOptionsLists = contactList ? contactList.map((contact: any) => ({
        label: contact.firstName + " " + contact.lastName,
        value: contact.id
    })) : [];

    const BranchOptionsLists = branchList ? branchList.map((branch: any) => ({
        label: branch.name,
        value: branch.id
    })) : [];

    const onSelectContact = (e: any) => {
        console.log(e);
        setErrorMessageContact('')
        setSelectedContacts(e);
    };
    const onSelectBranch = (e: any) => {
        console.log(e);
        setErrorMessageBranch('')
        setSelectedBranch(e);
    };
    const onSelectStaff = (e: any) => {
        console.log(e);
        setErrorMessageStaff('')
        setSelectedStaff(e);
    };

    const [selectedServiceData, setSelectedServiceData] = useState<any>(null);
    const handleServiceSelect = (service: any) => {
        setSelectedServiceData(service);
        console.log(JSON.stringify(service, null, 2));
    };

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
        if (!event.target.checked) {
            setEndTime('');
        }
    };
    const handleEndTime = (event: any) => {
        setEndTime(event.target.value);
    };

    const validationSchema = object().shape({
        ContactName: string(),
        Email: string()
            .required('Email is required'),
        PhoneNumber: string(),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitFirstForm = (data: any) => {
        if (modalType == 'Add') {
            setErrorMessageContact('')
            if (seletedContacts != null && seletedContacts.length == 0) {
                setErrorMessageContact('Please select atleast one contact')
                return;
            }
            const contactObjList: any = [];
            seletedContacts.forEach((element) => {
                let contact = contactList.find((x: any) => x.id == element);
                if (contact != null) {
                    contactObjList.push({
                        contact: contact,
                        contactId: element,
                        appointmentId: 0
                    })
                }
            });

            setErrorMessageBranch('')
            if (seletedBranch != null && seletedBranch.length == 0) {
                setErrorMessageBranch('Please select atleast one Branch')
                return;
            }
            const branchObjList: any = [];
            seletedBranch.forEach((element) => {
                let branch = branchList.find((x: any) => x.id == element);
                if (branch != null) {
                    branchObjList.push({
                        branchId: element,
                        appointmentId: 0
                    })
                }
            });

            data.appointmentBranches = (branchObjList != null) ? branchObjList : [];
            data.appointmentContacts = (contactObjList != null) ? contactObjList : [];
            console.log(JSON.stringify(data, null, 2));
            setcontactData(data);
            setOpenModalSecond(true);
            setOpenModal(Close);
        } else {
            setErrorMessageContact('')
            if (seletedContacts != null && seletedContacts.length == 0) {
                setErrorMessageContact('Please select atleast one contact')
                return;
            }
            const contactObjList: any = [];
            seletedContacts.forEach((element) => {
                let contact = contactList.find((x: any) => x.id == element);
                if (contact != null) {
                    contactObjList.push({
                        contact: contact,
                        contactId: element,
                        appointmentId: 0
                    })
                }
            });

            setErrorMessageBranch('')
            if (seletedBranch != null && seletedBranch.length == 0) {
                setErrorMessageBranch('Please select atleast one Branch')
                return;
            }
            const branchObjList: any = [];
            seletedBranch.forEach((element) => {
                let branch = branchList.find((x: any) => x.id == element);
                if (branch != null) {
                    branchObjList.push({
                        branchId: element,
                        appointmentId: 0
                    })
                }
            });

            data.appointmentBranches = (branchObjList != null) ? branchObjList : []
            data.appointmentContacts = (contactObjList != null) ? contactObjList : [];
            console.log(JSON.stringify(data, null, 2));
            setcontactData(data);
            setOpenModalSecond(true);
            setOpenModal(Close);
        }
    };

    const validationThirdFormSchema = object().shape({

        email: string(),
        phoneNumber: string(),
        appointmentDate: date()
            .required('AppointmentDate is required'),
        startTime: string()
            .required('StartTime is required'),
        endTime: string()
            .required('EndTime is required'),
        location: string(),
        appointmentServices: object().shape({
            appointmentId: string(),
            serviceId: string()
        }),
    });

    const {
        register: registerThirdForm,
        handleSubmit: handleSubmitThirdForm,
        reset: resetThirdForm,
        formState: { errors: errorsThirdForm },
    } = useForm({
        resolver: yupResolver(validationThirdFormSchema)
    });

    const {
        handleSubmit: handleSubmitFourthForm,
        reset: resetFourthForm,
    } = useForm({

    });

    const onSubmitFourthForm = (data: any) => {
        if (modalType == 'Add') {
            setErrorMessageStaff('')
            if (seletedStaff != null && seletedStaff.length == 0) {
                setErrorMessageStaff('Please select atleast one staff')
                return;
            }
            const staffObjList: any = [];
            seletedStaff.forEach((element) => {
                let staff = staffList.find((x: any) => x.id == element);
                if (staff != null) {
                    staffObjList.push({
                        staffId: element,
                        appointmentId: 0
                    })
                }
            });

            data.appointmentStaffInfos = (staffObjList != null) ? staffObjList : [];
            console.log(JSON.stringify(data, null, 2));
            setStaffData(data);
            setOpenModalForth(true);
            setOpenModalThird(false);
        } else {
            setErrorMessageStaff('')
            if (seletedStaff != null && seletedStaff.length == 0) {
                setErrorMessageStaff('Please select atleast one staff')
                return;
            }
            const staffObjList: any = [];
            seletedStaff.forEach((element) => {
                let staff = staffList.find((x: any) => x.id == element);
                if (staff != null) {
                    staffObjList.push({
                        staffId: element,
                        appointmentId: 0
                    })
                }
            });

            data.appointmentStaffInfos = (staffObjList != null) ? staffObjList : [];
            console.log(JSON.stringify(data, null, 2));
            setStaffData(data);
            setOpenModalForth(true);
            setOpenModalThird(false);
        }
    }



    const onSubmitThirdForm = (data: any) => {
        // setIsLoading(false);
        if (modalType === 'Add') {
            console.log("add");
            let finalObj = { ...contactData, ...staffData, ...data }
            finalObj.appointmentServices = [];
            finalObj.appointmentServices.push({
                appointmentId: 0,
                serviceId: selectedServiceData.serviceId
            })
            // finalObj.appointmentStaffInfos = [];
            // finalObj.appointmentStaffInfos.push({
            //     appointmentId: 0,
            //     staffId: 1
            // })
            // finalOb.startTime = finalOb.startTime  + ":00" 
            finalObj.startTime = finalObj.startTime + ":00"
            finalObj.endTime = finalObj.endTime + ":00"
            finalObj.id = 0;
            finalObj.amount = 0;
            console.log(JSON.stringify(finalObj, null, 2));
            dispatch(addCalendarAppointment(finalObj))
                .unwrap()
                .then(() => {
                    navigate("/calendar");
                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
            setcontactData(data);
        } else {
            let finalObj = { ...contactData, ...staffData, ...data }
            finalObj.appointmentServices = [];
            finalObj.appointmentServices.push({
                appointmentId: 0,
                serviceId: selectedServiceData.id
            })
            // finalObj.appointmentStaffInfos = [];
            // finalObj.appointmentStaffInfos.push({
            //     appointmentId: 0,
            //     staffId: 1
            // })
            // finalOb.startTime = finalOb.startTime  + ":00" 
            finalObj.startTime = finalObj.startTime + ":00"
            finalObj.endTime = finalObj.endTime + ":00"
            finalObj.id = 0;
            finalObj.amount = 0;
            console.log(JSON.stringify(finalObj, null, 2));
            console.log("update appointment");
            dispatch(addCalendarAppointment(finalObj))
                .unwrap()
                .then(() => {
                    navigate("/calendar");
                })
                .catch(() => {
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
            setcontactData(data);
        }
    };







    useEffect(() => {
        if (modalType === 'Edit' && appointment) {
            setExistingAppointment(appointment);
            reset(appointment);
        }
    }, [appointment, modalType, reset]);


    return (
        <>
            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center min-w-2xl`}
                show={openDrawer}
                position={modalPlacement}
                onClose={() => { setOpenModal(Close); reset() }}
                initialFocus={emailInputRef}
                size="lg"
            >
                <Modal.Header className="bg-white-full p-5">
                    <span className="text-blue_gray-900 text-xl">{title}</span>
                </Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmit(onSubmitFirstForm)}>
                        <div className="space-y-6 mt-3">
                            <div className='mb-4'>
                                <SelectBox
                                    className={`font-medium text-base text-left border border-gray-200 border-solid  rounded-lg block w-full p-3 placeholder:text-blue_gray-500`}
                                    placeholderClassName="text-blue_gray-900"

                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={true}
                                    options={BranchOptionsLists}
                                    isSearchable={true}
                                    placeholder="Select Branch"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    variant="outline"
                                    errors={[errorMessageBranch]}
                                    onChange={(e) => onSelectBranch(e)}
                                />
                            </div>
                            <div className=''>
                                <SelectBox
                                    className={`font-medium text-base text-left border border-gray-200 border-solid  rounded-lg block w-full p-3 placeholder:text-blue_gray-500`}
                                    placeholderClassName="text-blue_gray-900"

                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={true}
                                    options={ContactOptionsLists}
                                    isSearchable={true}
                                    placeholder="Select Users"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    variant="outline"
                                    errors={[errorMessageContact]}
                                    onChange={(e) => onSelectContact(e)}
                                />
                            </div>
                            <div>
                                <label className="block mb-1 text-left text-sm">  Email
                                </label>
                                <input type="email" className={`border border-gray-200 border-solid text-xs rounded-lg block w-4/5 p-3 placeholder:text-blue_gray-500 h ${errors.Email ? 'is-invalid' : ''}`}
                                    placeholder="Enter Email"
                                    {...register('Email')}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.Email?.message?.toString()}</div>
                            </div>
                            <div>
                                <label className="block mb-1 text-left text-sm ">
                                    Phone Number
                                </label>
                                <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-4/5 p-3 placeholder:text-blue_gray-500 h ${errors.PhoneNumber ? 'is-invalid' : ''}`}
                                    placeholder={"Enter Phone Number"}
                                    {...register('PhoneNumber')}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.PhoneNumber?.message?.toString()}</div>
                            </div>
                            <div className="flex md:flex-col flex-row justify-end w-full">
                                <div className="flex flex-row gap-2 items-start justify-end w-full mt-20">
                                    <button onClick={() => {
                                        reset()
                                        setOpenModal(Close);
                                    }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Cancel</p>
                                    </button>
                                    <button type='submit' className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                        <p>Continue</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* // second modal */}

            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center min-w-2xl`}
                show={openModalSecond}
                position={modalPlacementSecond}
                onClose={() => { setOpenModalSecond(false); reset(); }}
                size="lg"
            >
                <Modal.Header className='bg-white-full p-5'>Contact Address</Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form>
                        {/* onSubmit={handleSubmit(onSubmitSecondForm)} */}
                        <div className="space-y-6 px-5">
                            <div>
                                <input
                                    className="text-sm  text-left border border-gray-200 border-solid  rounded-lg block w-full p-3 placeholder:text-blue_gray-500"
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div
                                className="md:flex-col flex flex-col gap-4 md:grid-cols-1 grid-cols-2 justify-start w-auto md:w-full">

                                {bookingServiceList && bookingServiceList.length > 0 ? (
                                    bookingServiceList
                                        .filter((bookingService: any) => {
                                            if (searchTerm === '' || searchTerm.trim() === '') {
                                                return bookingService;
                                            } else if (
                                                bookingService.serviceName && bookingService.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
                                            ) {
                                                return bookingService;
                                            }
                                        })
                                        .map((bookingService: any, i: number) => (
                                            <>
                                                <div className={`border border-gray-10 border-solid flex flex-col gap-[2px] items-start justify-start rounded-[7px] w-auto sm:w-full p-3 ${selectedServiceData === bookingService ? 'border-green-dark border-2' : ''}`} key={i}
                                                    onClick={() => handleServiceSelect(bookingService)}>
                                                    <div className="flex flex-col items-start justify-start w-auto">
                                                        <img
                                                            className="h-20 w-full"
                                                            src={bookingService.serviceImageUrl}
                                                            alt="arrowright"
                                                        />
                                                    </div>
                                                    <div className="flex flex-row text-base items-center justify-between gap-[200px]">
                                                        {bookingService.serviceName}
                                                    </div>
                                                    <div className="flex flex-col text-sm text-gray-600 items-start justify-start w-auto">
                                                        {bookingService.serviceDescription}
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                ) : (
                                    <div className="border border-gray-100 border-solid flex flex-col gap-[2px] items-center justify-center rounded-[7px] w-auto sm:w-full p-3 text-center">
                                        <div className="flex items-center justify-center w-full">
                                            Automatic Driving Lesson
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex md:flex-col flex-row justify-end w-full">
                                <div className="flex flex-row gap-2 items-start justify-end w-[278px] mt-10">
                                    <button onClick={() => { setOpenModalSecond(false); setOpenModal(Close); }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Back</p>
                                    </button>
                                    <button type="button" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                                        onClick={() => {

                                            setOpenModalThird(true);
                                            setOpenModalSecond(false);
                                        }}
                                    >
                                        <p>Continue</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* third Modal */}

            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center min-w-2xl`}
                show={openModalThird}
                position={modalPlacementThird}
                onClose={() => { setOpenModalThird(false); reset(); }}
                size="lg"
            >
                <Modal.Header className='bg-white-full p-5'>Contact Address</Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmitFourthForm(onSubmitFourthForm)}>
                        <div className="space-y-6 mt-6 w-full">
                            <div className="max-w-sm w-full">
                                <label className="block mb-1 text-left text-sm">
                                    Select Staffs
                                </label>
                                <SelectBox
                                    className="h-[52px] text-base text-left w-full bg-white-full"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={true}
                                    name="frameFiftySix"
                                    options={StaffOptionsLists}
                                    isSearchable={true}
                                    placeholder="Staffs"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    variant="outline"
                                    errors={[errorMessageStaff]}
                                    onChange={(e) => onSelectStaff(e)}
                                />
                            </div>
                            <div className="flex md:flex-col flex-row justify-end w-full">
                                <div className="flex flex-row gap-2 items-end justify-end w-[278px]">
                                    <button onClick={() => setOpenModalSecond(true)} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Back</p>
                                    </button>
                                    <button type="submit" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                                    >
                                        <p>Next</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* forth modal */}

            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center min-w-2xl`}
                show={openModalForth}
                position={modalPlacementForth}
                onClose={() => { setOpenModalForth(false); reset(); }}
                size="lg"
            >
                <Modal.Header className='bg-white-full p-5'>Contact Address</Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmitThirdForm(onSubmitThirdForm)}>
                        <div className="space-y-6 mt-2 w-full">
                            <div className="flex md:flex-col flex-row gap-[49px] items-center justify-start max-w-[1160px] w-full">
                                <div className="flex flex-1 flex-row items-center justify-start max-w-[832px] w-full">
                                    <img className="w-10 h-10 rounded-full" src="https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" alt="Jese image" />
                                    <div className="ps-3">

                                        {(contactData != null) ? contactData.firstName : ""}
                                        {(contactData != null) ? contactData.Email : ""}
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2 items-start justify-end w-[278px]">
                                    <button
                                        onClick={() => setOpenModalForth(false)}
                                        className="cursor-pointer h-8 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[100px] hover:text-white-A700 hover:bg-deep_purple-A400"
                                    >Change Client
                                    </button>
                                </div>
                            </div>
                            <div className="md:flex md: grid grid-cols-6 gap-4 my-4">
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-1 text-left text-sm">
                                        Date
                                    </label>
                                    <input type="date" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${errorsThirdForm.appointmentDate ? 'is-invalid' : ''}`}
                                        {...registerThirdForm('appointmentDate')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errorsThirdForm?.appointmentDate?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-1 text-left text-sm">
                                        Start time
                                    </label>
                                    <input type="time" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${errorsThirdForm.startTime ? 'is-invalid' : ''}`}

                                        {...registerThirdForm('startTime')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errorsThirdForm?.startTime?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-1 text-left text-sm">
                                        End time
                                    </label>
                                    <input type="time" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${errorsThirdForm.endTime ? 'is-invalid' : ''}`} placeholder="Zip postal"

                                        {...registerThirdForm('endTime')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errorsThirdForm?.endTime?.message?.toString()}</div>
                                </div>
                            </div>
                            <div className="flex w-full col-span-2 items-stretch">
                                <div className='self-end w-full'>
                                    <input type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                    <label className="text-blue_gray-900 text-sm font-bold ml-2">
                                        Repeats
                                    </label>
                                    {isChecked && (
                                        <>
                                            <div className="grid grid-cols-4 gap-4 my-4 w-full">
                                                <div className="relative max-w-sm col-span-2">
                                                    <label className="block mb-1 text-left text-sm">
                                                        Repeats
                                                    </label>
                                                    <select id="countries" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500`}
                                                    // {...registerThirdForm('repeat')}
                                                    >
                                                        <option value="">No Repeats</option>
                                                        <option value="weekly">Weekly</option>
                                                        <option value="daily">Daily</option>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="annually">Annually</option>
                                                    </select>
                                                </div>
                                                <div className="relative max-w-sm col-span-2">
                                                    {/* <label className="block mb-1 text-left text-sm">
                                                        For
                                                    </label> */}
                                                    {/* <select id="countries" className={`border border-gray-200 border-solid text-xs text-sm rounded-lg block w-full p-3 placeholder:text-blue_gray-500`}
                                                        {...registerThirdForm('repeatTime')}
                                                    >
                                                        <option value="">No Repeats</option>
                                                        <option value="2times">2 times</option>
                                                        <option value="3times">3 times</option>
                                                        <option value="4times">4 times</option>
                                                        <option value="5times">5 times</option>
                                                        <option value="6times">6 times</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="relative max-w-sm col-span-2">
                                <label className="block mb-1 text-left text-sm">
                                    Location
                                </label>
                                <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500`} placeholder="Location"
                                    //  ${errorsThirdForm.Location ? 'is-invalid' : ''}
                                    {...registerThirdForm('location')}
                                />
                            </div>
                            <div className="relative col-span-1 w-full">
                                <label className="block mb-1 text-left text-sm">
                                    1 service•1:30 PM(1hr)•LKR 4
                                </label>
                                <div className="border border-gray-100 border-solid flex flex-col gap-[2px] items-start justify-start rounded-[7px] w-auto sm:w-full p-3 text-sm">
                                    <div className="flex flex-row items-center justify-between w-full">

                                        {(selectedServiceData != null) ? selectedServiceData.serviceName : ""}
                                        <div className='flex flex-row items-end justify-end gap-[15px] w-full'>
                                            <svg
                                                className="fill-current hover:text-red-600"
                                                width="15"
                                                height="15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                            </svg>
                                        </div>

                                    </div>
                                    <div className="flex flex-col items-start justify-start w-auto">
                                        {(selectedServiceData != null) ? selectedServiceData.serviceDuration : ""}

                                        <br />
                                        {(selectedServiceData != null) ? selectedServiceData.pricingtype : ""}
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white flex flex-row w-full border-b border-gray-200">
                            </div>
                            <div className="flex flex-col items-start justify-start rounded-lg w-auto mt-3">
                                <div className="flex items-center">
                                    <input type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Notify client with an email
                                    </label>
                                </div>
                            </div>
                            <div className="flex md:flex-col flex-row justify-end w-full">
                                <div className="flex flex-row gap-2 items-end justify-end w-[278px]">
                                    <button
                                        type='button'
                                        onClick={() => { setOpenModalThird(true); setOpenModalForth(false); }}
                                        className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                                    >Back</button>
                                    <button
                                        // disabled={isLoading}
                                        type='submit'
                                        // onClick={() => setOpenForm_3(false)}
                                        className="cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-xs w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                                    >
                                        save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>

        </>
    );
}

export { AddAppointment };
