import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { getBookingServiceTypeData, addServiceTypeData, } from '../../../store/slice/bookingServiceType-Slice';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";

function AddServiceType({ openDrawer, Close, modalType, serviceType }: { openDrawer: boolean, Close: any, modalType: string, serviceType?: any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Service Type' : 'Edit Service Type'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingServiceType, setExistingServiceType] = useState(serviceType);


    const validationSchema = object().shape({
        serviceTypeName: string()
            .required('name is required'),
        description: string()
            .required('description is required'),
        category: string()
            .required('category is required'),
        imageUrl: string()
            .required('imageUrl is required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        console.log(modalType);
        if (modalType === 'Add') {
            console.log(JSON.stringify(data, null, 2));
            dispatch(addServiceTypeData(data))
                .unwrap()
                .then(() => {
                    setOpenModal(false)
                    dispatch(getBookingServiceTypeData())
                    reset();
                    navigate("/settings/servicetypes")
                })
                .catch(() => {
                    // setLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
        } else {

            console.log(JSON.stringify(data, null, 2));
            // dispatch(addServiceTypeData({ id: serviceType.id, data }))
            //     .unwrap()
            //     .then(() => {
            //         setOpenModal(false)
            //         dispatch(getBookingServiceTypeData())
            //         navigate("/settings/role")
            //     })
            //     .catch(() => {
            //         // setLoading(false);
            //     })
            //     .finally(() => {
            //         setTimeout(() => {
            //         }, 300);
            //     });
        }

    };

    useEffect(() => {
        if (modalType === 'Edit' && serviceType) {
            setExistingServiceType(serviceType);
            reset(serviceType);
        }
    }, [serviceType, modalType, reset]);


    return (
        <Modal
            className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => {reset(); setOpenModal(Close);}}
            initialFocus={emailInputRef}
            size="lg"
        >
            <Modal.Header className="bg-white-full p-5">
                <span className="text-blue_gray-900 text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-full">
                <div className="space-y-5 w-full">
                    <div className='p-5'>
                        <form className="mx-auto" onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-3 max-w-lg">
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Service Type Name
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.serviceTypeName ? 'is-invalid' : ''} `}
                                        placeholder="Enter ServiceType Name"
                                        {...register('serviceTypeName')}
                                    />
                                    <div className="font-medium text-left mt-2 text-xs text-red-600" >{errors?.serviceTypeName?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Category
                                    </label>
                                    <select id="countries" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 `}
                                        {...register('category')}>
                                        <option value="">Categorys</option>
                                        <option value="appointment">Appointment</option>
                                        <option value="class">Class</option>
                                        <option value="course">Course</option>
                                    </select>
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.category?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Image URL
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                    ${errors.imageUrl ? 'is-invalid' : ''}`}
                                        placeholder="Enter image Url"
                                        {...register('imageUrl')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.imageUrl?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-full col-span-2">
                                    <label className="block mb-2">
                                        Description
                                    </label>
                                    <textarea
                                        className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                        {...register('description')}
                                        placeholder="Describe Service Type"
                                    ></textarea>

                                </div>
                                <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                                    <div className="flex flex-row gap-2 items-end justify-end w-full">
                                        <button onClick={() => {
                                            reset()
                                            setOpenModal(Close);
                                        }} type="button" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border border-gray-200">
                                            <p>Cancel</p>
                                        </button>
                                        <button type="submit" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                            <p>Save</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { AddServiceType };
