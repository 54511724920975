import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getBranch } from '../../../store/slice/branch-slice';
import { getBookingServiceTypeData } from '../../../store/slice/bookingServiceType-Slice';

import AddServiceStepOne from '../../../components/AddService/AddServiceStepOne';
import AddServiceStepTwo from '../../../components/AddService/AddServiceStepTwo';
import AddServiceStepThree from '../../../components/AddService/AddServiceStepThree';
import AddServiceStepFour from '../../../components/AddService/AddServiceStepFour';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { addBooking } from '../../../store/slice/bookingservice-slice';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading/loading';

const AddServicePage = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState<any>({});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { branchList } = useSelector((state: any) => state.branch);
    // const { bookingServiceTypeList } = useSelector((state: any) => state.serviceType);

    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(getBranch());
            dispatch(getBookingServiceTypeData()).finally(()=>{
                setIsLoading(false);
            });
            effectSeviceCall.current = true;
        }
       // return () => { effectSeviceCall.current = true; };
    }, [dispatch]);

    const BranchOptionsLists = branchList ? branchList.map((branch: any) => ({
        label: branch.name,
        value: branch.id
    })) : [];

    const handleNextStep = (stepData: any) => {
        setFormData({ ...formData, ...stepData });
        if(currentStep ==2){
            console.log(formData)
            handleSubmit(stepData);
        }else{
            setCurrentStep(currentStep + 1);
        }

    };

    

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (dataObj: any) => {
        console.log(dataObj);
        const data = new FormData();
        data.append('myJson', JSON.stringify(dataObj));
        data.append('binary', dataObj.file);

        setIsLoading(true);
        dispatch(addBooking(data))
            .unwrap()
            .then(() => {
                navigate("/services");
            })
            .catch(() => {
                setIsLoading(false);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            });

        // console.log('Submitted Data:', { ...formData, ...data });
    };

    return (
        <div className="flex flex-col justify-start gap-5 items-start w-full">
            <div className="w-full flex-col justify-start items-start flex gap-3">
                <h1 className="text-2xl">Add Services</h1>
                <div className="w-full flex md:justify-start  justify-center items-center">
                    <ol className="w-[50%] flex justify-between items-center">
                        {[...Array(2)].map((_, index) => {
                            const step = index + 1;
                            return (
                                <li key={step} className="flex-1 text-center">
                                    <div className={`px-6 py-2 text-white-full rounded-sm border ${currentStep === step ? 'bg-green-dark' : 'bg-gray-300'}`}>
                                        <h3 className="font-base">Step {step}</h3>
                                    </div>
                                </li>
                            );
                        })}
                    </ol>
                </div>
            </div>
            <div className="w-full">
            {(isLoading) && <Loading></Loading>}
                {currentStep === 1 && <AddServiceStepOne branchOptions={BranchOptionsLists} onSubmitStep={handleNextStep} initialData={formData} />}
                {currentStep === 2 && <AddServiceStepTwo onPreviousStep={handlePreviousStep} onSubmitStep={handleNextStep} initialData={formData} />}
                {/* {currentStep === 3 && <AddServiceStepThree onPreviousStep={handlePreviousStep} onSubmitStep={handleNextStep} initialData={formData} />} */}
                {/* {currentStep === 4 && <AddServiceStepFour formData={formData} onSubmitStep={handleSubmit} onPreviousStep={handlePreviousStep} initialData={formData} />} */}

                {/* Conditional rendering for the "Previous" button */}
                {/* {currentStep > 1 && currentStep < 4 && (
                    <button
                        onClick={handlePreviousStep}
                        className="flex gap-2 px-4 py-2 mt-4 text-sm font-medium text-white-full hover:text-black bg-gray-500 rounded-md border border-gray-200 hover:bg-gray-400"
                    >
                        Previous
                    </button>
                )} */}
            </div>
        </div>
    );
};

export default AddServicePage;
