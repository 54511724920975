import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { getBranch } from '../../../store/slice/branch-slice';
import { useSelector } from 'react-redux';
import { addStaff, fetchStaffData, putStaffData } from '../../../store/slice/staff-slice';
import { SelectBox } from '../../SelectBox';
import { getRole } from '../../../store/slice/role-slice';

function AddStaff({ openDrawer, Close, modalType, staff }: { openDrawer: boolean, Close: any, modalType: string, staff?: any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Staff' : 'Edit Staff'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openModalSecond, setOpenModalSecond] = useState(false);
    const [modalPlacementSecond, setModalPlacementSecond] = useState('center')
    const { roleList } = useSelector((state: any) => state.role);
    const { branchList } = useSelector((state: any) => state.branch);
    const [errorMessageContact, setErrorMessageContact] = useState<string>('');
    const [seletedBranches, setSelectedBranches] = useState<any[]>([]);
    const [existingStaff, setExistingStaff] = useState(staff);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [modalSize, setModalSize] = useState<string>('lg');



    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(getBranch());
            dispatch(fetchStaffData());
            dispatch(getRole());
        }
        return () => { effectSeviceCall.current = true; };
    }, [dispatch]);

    const BranchOptionsLists = branchList ? branchList.map((branch: any) => ({
        label: branch.name,
        value: branch.id
    })) : [];

    const onSelectContact = (e: any) => {
        console.log(e);
        setErrorMessageContact('')
        setSelectedBranches(e)
    };


    const validationSchema = object().shape({
        firstName: string()
            .required('FirstName is required'),
        lastName: string()
            .required('LastName is required'),
        email: string()
            .required('Email is required'),
        phoneNumber: string()
            .required('PhoneNumber is required'),
        RoleName: string()
            .required('userRole is required'),
        description: string(),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        if (seletedBranches != null && seletedBranches.length === 0) {
            setErrorMessageContact('Please select atleast one branch')
            return;
        }
        const branchObjList: any = [];
        seletedBranches.forEach((element) => {
            let branch = branchList.find((x: any) => x.id === element);
            if (branch != null) {
                branchObjList.push({
                    branch: branch,
                    branchId: element,
                    staffId: 0
                })
            }
        });
        data.staffBranches = (branchObjList != null) ? branchObjList : [];
        console.log(JSON.stringify(data, null, 2));
        if (modalType === 'Add') {
            console.log("this is create");
            console.log(JSON.stringify(data, null, 2));
            dispatch(addStaff(data))
                .unwrap()
                .then(() => {
                    dispatch(fetchStaffData())
                    navigate("/staff");
                })
                .catch((error: any) => {
                    console.error("Error adding new staff:", error);
                    // Handle error
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });

        } else {
            console.log("this is Update");
            console.log(JSON.stringify(data, null, 2));
            dispatch(putStaffData({ id: staff.id, data }))
                .unwrap()
                .then(() => {
                    dispatch(fetchStaffData())
                    navigate("/staff");
                })
                .catch((error: any) => {
                    console.error("Error updating staff:", error);
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 300);
                });
        }
    };





    useEffect(() => {
        if (modalType === 'Edit' && staff) {
            setExistingStaff(staff);
            reset(staff);
        }
    }, [staff, modalType, reset]);


    return (
        <>
            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center min-w-2xl`}
                show={openDrawer}
                position={modalPlacement}
                onClose={() => { setOpenModal(Close); reset() }}
                initialFocus={emailInputRef}
                size={modalSize}
            >
                <Modal.Header className="bg-white-full p-5">
                    <span className="text-blue_gray-900 text-xl">{title}</span>
                </Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4 w-full md:flex md:flex-col">
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            First Name
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.firstName ? 'is-invalid' : ''}`}
                                            placeholder="e.g Mike MCstaff"
                                            {...register('firstName')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.firstName?.message?.toString()}</div>
                                    </div>
                                </div>
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Last Name
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.lastName ? 'is-invalid' : ''}`} placeholder="e.g Mike MCstaff"
                                            {...register('lastName')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.lastName?.message?.toString()}</div>
                                    </div>
                                </div>
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Email
                                        </label>
                                        <input type="email" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h  ${errors.email ? 'is-invalid' : ''}`} placeholder="exaample@example.com"
                                            {...register('email')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.email?.message?.toString()}</div>
                                    </div>
                                </div>
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Phone Number
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.phoneNumber ? 'is-invalid' : ''}`} placeholder="123-456-89"
                                            {...register('phoneNumber')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.phoneNumber?.message?.toString()}</div>
                                    </div>
                                </div>
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Role
                                        </label>
                                        <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500 placeholder:text-txtInterMedium14 ${errors.RoleName ? 'is-invalid' : ''}`}
                                            {...register('RoleName')}
                                        >
                                            {roleList?.map((role: any) => (
                                                <option key={role.id} value={role.name}>{role.description}</option>
                                            ))}
                                        </select>
                                        <div className="font-medium text-xs text-red-600" >{errors?.RoleName?.message?.toString()}</div>
                                    </div>
                                </div>
                                <div className="max-w-sm col-span-1 w-full">
                                    <div className="max-w-sm w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Branch Names
                                        </label>
                                        <SelectBox
                                            className="h-[52px] text-base text-left w-full bg-white-full"
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={true}
                                            name="frameFiftySix"
                                            options={BranchOptionsLists}
                                            isSearchable={true}
                                            placeholder="Branches"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            variant="outline"
                                            onChange={(e) => onSelectContact(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 w-full">
                                <div className="flex flex-col gap-[1px] items-start justify-start md:w-full w-full">

                                    Description
                                    <textarea
                                        className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                        {...register('description')}
                                        placeholder="Describe what you offer and why clients should book"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="flex md:flex-col gap-2 flex-row justify-start w-full pb-3">
                                <div className="flex flex-row gap-2 items-end justify-end w-full">
                                    <button onClick={() => {
                                        reset()
                                        setOpenModal(Close);
                                    }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Cancel</p>
                                    </button>
                                    <button type="submit" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                                    // onClick={() => {
                                    //     setOpenModal(Close);
                                    //     setOpenModalSecond(true);
                                    // }}
                                    >
                                        <p>Next</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* // second modal */}

            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center w-full`}
                show={openModalSecond}
                position={modalPlacementSecond}
                onClose={() => { setOpenModalSecond(false); reset(); }}
                size="lg"
            >
                <Modal.Header className='bg-white-full p-5'>Contact Address</Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-3">
                            {/* form */}
                            <div className="flex md:flex-col gap-2 flex-row justify-start w-full pb-3">
                                <div className="flex flex-row gap-2 items-end justify-end w-full">
                                    <button onClick={() => {
                                        setOpenModalSecond(false);
                                        setOpenModal(Close);

                                    }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Back</p>
                                    </button>
                                    <button type="submit" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                        <p>Save</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}

export { AddStaff };
