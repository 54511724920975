import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

// const addBooking = (data:any) => {
//   return axiosInstance.post("bookingservice", data);
// };

const getBookingServicePricePlan = async () => {
  try {
    const response = await axiosInstance.get("BookingServicePricePlan");
    //console.log(response);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const BookingServicePricePlan = {
    //addBooking,
    getBookingServicePricePlan,
}

export default BookingServicePricePlan;