import React from 'react';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
        <img
                className="rounded-md w-30 h-30 drop-shadow-lg"
                src="/1.gif"
                alt="Loading..."
                width={100} height={100} 
              />
    </div>
  );
};

export default Loading;