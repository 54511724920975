import { LocalStorageService } from "./localstorage.service";
import axiosInstance from "./axiosInstance";
import { jwtDecode } from "jwt-decode";
const keyvalue = process.env.REACT_APP_LOCAL_KEY || 'personalsuite-userinfo';

const register = (data:any) => {
  return axiosInstance.post("tenant", data);
};

const login = (data:any) => {
  return axiosInstance
    .post("auth/login",data)
    .then((response:any) => {
      if (response.data!=null) {
        LocalStorageService.setItem(keyvalue, response.data)
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem(keyvalue);
};

const getCurrentUser = () => {
  return LocalStorageService.getItem(keyvalue);
};

const getUserName =() => {
  let data = LocalStorageService.getItem<any>(keyvalue)
  if (data == null) {
    return '';
  }
  let decoded: any = getTokenObjectData(data.token);
  if (decoded != null) {
    return decoded['fullName'];
  }
  return '';
}

const getTokenObjectData= (token: string) =>{
  let decoded: any = jwtDecode(token);
  if (decoded != null) {
    return decoded;
  }
  return null as any;
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getUserName
}

export default AuthService;