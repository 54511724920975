import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../components/Dropdown";
import OrderList from "../../components/OrderList";
import { HeaderButton } from "../../components";
import { CiFilter } from "react-icons/ci";
import { fetchOrderListData } from "../../store/slice/order-slice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/loading";

const Orders: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const { orderList } = useSelector((state: any) => state.order);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const today = new Date();
    const [listItem, setListItem] = useState<any[]>([]);

    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchOrderListData()).finally(()=>{
                setIsLoading(false);
            });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);

    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });

    useEffect(()=>{
        if(orderList!=null && orderList.length>0){
            setListItem(orderList);
        }

    },[orderList])

    const toggleAddModal = () => {
        setOpenModal(!openModal);
    };

    const onChangeOrder =(val: number, index: number) =>{
        setListItem(prevItems =>
            prevItems.map((item: any, i: number) =>
              i === index ? { ...item, orderStatus: val } : item
            )
          );
    }

    return (
        <>
            <div className="flex flex-col justify-start gap-3 items-start w-full">
                <div className="w-full flex-col justify-start items-start flex gap-1">
                    <h1 className="text-2xl">Orders</h1>
                    <p className="text-gray-400 text-sm">{formattedDate}</p>
                    <div className="flex flex-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5 ">
                        <div className="flex flex-row gap-2" ref={dropdownRef}>
                            <div className="flex gap-3 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md hover:bg-gray-100 placeholder-text-sm">
                                <div className="relative">
                                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="text" className="block ps-8 text-sm border text-gray-900 rounded-md h-10" placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button onClick={() => setIsOpen(!isOpen)} type="button" className="flex gap-2 p-2 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 hover:bg-gray-100">
                                <div className="fill-black">
                                    <CiFilter className="w-6 h-6" />
                                </div>
                                <p className="mt-0.5">Filters</p>
                            </button>
                            {isOpen && (
                                <div className="left-72 ml-4 border absolute mt-8 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 bg-white-full">
                                    <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div>
                            <HeaderButton buttonName="Add Order" isAdd={false} onClickAdd={toggleAddModal}></HeaderButton>
                        </div>
                    </div>
                </div>

                <div className="w-full h-screen">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg pb-[50px]">
                    {(isLoading) && <Loading></Loading>}
                        <OrderList searchTerm={searchTerm} filteredOrders={listItem} changeOrder={(val: number, index: number)=> onChangeOrder(val, index)}/>
                    </div>
                    {/* <AddOrder openDrawer={openModal} Close={toggleAddModal} modalType="Add" /> */}
                </div>
            </div>
        </>
    );
}

export default Orders;
