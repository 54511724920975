import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { fecthDeliveryInfo, updateDeliveryInfo } from "../../../store/slice/order-slice";
import Moment from "react-moment";
import Loading from "../../Loading/loading";
import { MdDeleteOutline } from "react-icons/md";

const calculateEndTime = (start: Date) => {
  const end = new Date(start.getTime() + 60 * 60 * 1000); // Add 1 hour
  return end;
};

const formatTime = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

function OrderStatusDrawer({
  orderStatus,
  orderId,
  openDrawer,
  onClose,
}: {
  orderStatus?: number;
  orderId: number;
  openDrawer: boolean;
  onClose: any;
}) {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(orderStatus);
  const [startTime, onStartChange] = useState(formatTime(new Date()));
  const [endTime, onEndChange] = useState(formatTime(calculateEndTime(new Date())));
  const { deliveryList } = useSelector((state: any) => state.order);

  const validationSchema = object().shape({
    processDate: string(),
    processTime: string(),
    personName: string().required("Contact Person is required"),
    vehicleNumber: string().required("Vehicle Number is required"),
    contactNumber: string().required("Contact Number is required"),
    description: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (orderId != null && orderId > 0) {
      dispatch(fecthDeliveryInfo(orderId)).finally(() => {
        setIsLoading(false);
      });
    }
  }, [orderId]);

  useEffect(()=>{
    if(deliveryList!=null && deliveryList.length > 0){
      console.log(deliveryList);
      if(deliveryList[0].orderStatus == 5){
        setSelectedOption(6)
      }
      
    }

  }, [deliveryList])

  const onSubmit = (data: any) => {
    data.processDate = startDate;
    data.processTime = startTime + "-" + endTime;
    data.orderStatus = Number(selectedOption);
    if (orderId == null || orderId == 0) {
      alert('OrderId not available');
      return;
    }
    data.transactionOrderId = orderId;
    setIsLoading(true);
    dispatch(updateDeliveryInfo(data))
      .unwrap()
      .then(() => {
        onClose(data);
      })
      .catch(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        id="drawer-right-example"
        className={`fixed top-0 py-4 right-0 z-50 h-auto bottom-0 p-4 overflow-y-auto transition-transform ${
          openDrawer
            ? "translate-x-0 duration-500"
            : "translate-x-full duration-500"
        } bg-white-full w-[50%] drop-shadow-xl transition-transform duration-500 `}
        aria-labelledby="drawer-right-label"
      >
        <nav
          className="justify-between p-2 border border-gray-200 rounded-sm sm:flex sm:px-5 bg-white-A700"
          aria-label="Breadcrumb"
        >
          <div className="flex md:flex-col flex-row top-0 md:h-auto md:p-3 items-center w-full">
            <ul className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0 gap-2.5">
              <li>
                <button
                  className="text-white font-medium rounded-lg text-sm p-2.5"
                  type="button"
                  onClick={onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className="fill-current"
                    width="25"
                    height="25"
                    fill="red"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </button>
              </li>
              <li>
                <div className="flex flex-1 flex-col items-center justify-center max-w-[832px] w-full">
                  <text className="md:text-2xl sm:text-[22px] text-[26px] text-blue_gray-900 w-auto">
                    Update Status
                  </text>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <div className="mt-5 rounded-lg">
          <div className="flex items-center mb-4">
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="option"
                  value={5}
                  defaultChecked={selectedOption === 5}
                  onChange={(e: any) => {
                    setSelectedOption(e.target.value);
                  }}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <span className="text-gray-700">Order Accepted</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="option"
                  value={6}
                  defaultChecked={selectedOption === 6}
                  onChange={(e: any) => {
                    setSelectedOption(e.target.value);
                  }}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <span className="text-gray-700">Order Collected</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="option"
                  value={7}
                  defaultChecked={selectedOption === 7}
                  onChange={(e: any) => {
                    setSelectedOption(e.target.value);
                  }}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <span className="text-gray-700">Order Delivered</span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="option"
                  value={4}
                  defaultChecked={selectedOption === 4}
                  onChange={(e: any) => {
                    setSelectedOption(e.target.value);
                  }}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <span className="text-gray-700">Order Cancelled</span>
              </label>
            </div>
          </div>

          {isLoading && <Loading></Loading>}

          <div className="flex flex-row gap-2 mt-4 w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-2 md:grid-cols-2 gap-4 w-full"
            >
              {/* Left Column */}
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-700 text-left">
                    Process Date
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      setValue("processDate", date);
                      setStartDate(date);
                    }}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 text-left">
                    Person Name
                  </label>
                  <input
                    type="text"
                    value="Steve" // Hardcoded driver name
                    className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${
                      errors.personName ? "is-invalid" : ""
                    } `}
                    placeholder="Person Name"
                    {...register("personName")}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 text-left">
                    Description
                  </label>
                  <textarea
                    {...register("description")}
                    className="border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h"
                    rows={4}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-gray-700 text-left">
                    Process Time
                  </label>

                  <div className="flex">
                    <TimePicker
                      format="HH:mm"
                      closeClock={false}
                      disableClock={true}
                      className="border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h"
                      value={startTime}
                      onChange={(data: any) => {
                        console.log(data);
                        onStartChange(data);
                      }}
                    />
                    <TimePicker
                      format="HH:mm"
                      closeClock={false}
                      disableClock={true}
                      className="border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h"
                      value={endTime}
                      onChange={(data: any) => onEndChange(data)}
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 text-left">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    value="07932925437" // Hardcoded contact number
                    className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${
                      errors.contactNumber ? "is-invalid" : ""
                    } `}
                    placeholder="Enter Contact Number"
                    {...register("contactNumber")}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 text-left">
                    Vehicle Number
                  </label>
                  <input
                    type="text"
                    value="HWO8FTO" // Hardcoded vehicle number
                    className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${
                      errors.vehicleNumber ? "is-invalid" : ""
                    } `}
                    placeholder="Enter Vehicle Number"
                    {...register("vehicleNumber")}
                  />
                </div>
              </div>

              <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                <div className="flex flex-row gap-2 items-end justify-end w-full">
                  <button
                    type="submit"
                    className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                  >
                    <p>Submit</p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export { OrderStatusDrawer };
