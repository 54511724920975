import { Link } from "react-router-dom";

const ResetPassEmail = () => {
    return (
        <div className="w-full bg-white-full p-52">
            <div className="flex justify-center items-center w-full">
                <form className="w-full flex flex-col justify-center items-center mx-auto gap-5">
                    <div className="flex text-3xl font-medium text-black">
                        <p>Reset password</p>
                    </div>
                    <div className="flex flex-col w-96 gap-3">
                        <div className="relative">
                            <input type="text" id="email-address-icon" className="bg-gray-50 border border-green-dark text-black text-sm rounded-sm   block w-full p-2.5 focus:ring-green-dark dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 " placeholder="Email" />
                        </div>
                        <div className="flex justify-start items-start float-left">
                            <Link to={'/resetPassword2'}>
                            <button type="button" className="flex gap-3 py-1 p-3 px-5 me-2 text-sm font-medium text-white-full bg-green-dark rounded-lg border border-gray-200 hover:black hover:opacity-50">
                                <p>Reset password</p>
                            </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ResetPassEmail;