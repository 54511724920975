import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { OrderStatusDrawer } from "../Drawer/OrderStatus";
import { InvoiceDrawer } from "../Drawer/InvoiceDrawer";

interface OrderListProps {
  searchTerm: string;
  filteredOrders: any[];
  changeOrder:any;
}

const OrderList: React.FC<OrderListProps> = ({ searchTerm, filteredOrders, changeOrder }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openStatusDrawer, setOpenStatusDrawer] = useState(false);
  const [orderReference, setOrderReference] = useState<string>();
  const [orderInfo, setOrderInfo] = useState<any>(null);
  const [orderId, setOrderId] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isListView, setIsListView] = useState(true); // New state to toggle view



  const handleViewInvoice = (order: any) => {
    setOrderReference(order.transactionReference);
    setOpenDrawer(true);
  };


  const getStatus = (status: number) => {
    let response = <span className="bg-green-500 text-white-full px-2 py-1 rounded-md">Successful</span>;

    switch (status) {
      case 0:
        response = (
          <span className="bg-green-500 text-sm text-white-full px-2 py-1 rounded-md">
            Successful
          </span>
        );
        break;
      case 1:
        response = (
          <span className="bg-red-500 text-sm text-white-full px-2 py-1 rounded-md">
            Failed
          </span>
        );
        break;
      case 2:
        response = (
          <span className="bg-gray-500 text-white-full px-2 py-1 rounded-md">
            Failed
          </span>
        );
        break;
      case 3:
        response = (
          <span className="bg-gray-200 text-black px-2 py-1 rounded-md">
            Refund
          </span>
        );
        break;
      case 4:
        response = (
          <span className="bg-gray-200 text-black px-2 py-1 rounded-md">
            Cancelled
          </span>
        );
        break;
      case 5:
        response = (
          <span className="bg-gray-200 text-black px-2 py-1 rounded-md">
            Accepted
          </span>
        );
        break;
      case 6:
        response = (
          <span className="bg-gray-200 text-black px-2 py-1 rounded-md">
            Collected
          </span>
        );
        break;
      case 7:
        response = (
          <span className="bg-gray-200 text-black px-2 py-1 rounded-md">
            Delivered
          </span>
        );
        break;
      default:
        break;
    }

    return response;
  };

  const onClickAdd = (data: any, index: number) => {
    setOpenStatusDrawer(true);
    setOrderId(data.id);
    setOrderInfo(data);
    setSelectedIndex(index);
  };

  const toggleView = () => {
    setIsListView(!isListView); // Toggle between list and card views
  };

  const onCollectOrderStatus =(data:any) =>{
    console.log(data);
    if(data!=null){
      changeOrder(data.orderStatus, selectedIndex);
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Orders</h2>
        <button
          className="py-2 px-4 bg-blue-500 text-white rounded"
          onClick={toggleView}
        >
          Switch to {isListView ? "Card View" : "List View"}
        </button>
      </div>

      {isListView ? (
        // List view (table)
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Order Ref</th>
                <th className="py-2 px-4 border-b">Customer Name</th>
                <th className="py-2 px-4 border-b">Phone Number</th>
                <th className="py-2 px-4 border-b">Total Amount</th>
                <th className="py-2 px-4 border-b">Order Date</th>
                <th className="py-2 px-4 border-b">Order Status</th>
                <th className="py-2 px-4 border-b">Action</th>
                <th className="py-2 px-4 border-b">Invoice</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders &&
                filteredOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b">
                      {order.transactionReference}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {order.contactInfo.firstName}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {order.contactInfo.phoneNumber}
                    </td>
                    <td className="py-2 px-4 border-b">
                      £ {order.transactionAmount ? order.transactionAmount.toFixed(2) : "0.00"}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <Moment format="YYYY/MM/DD HH:mm">{order.orderDate}</Moment>
                    </td>
                    <td className="py-2 px-4 border-b">
                      {getStatus(order.orderStatus)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        type="button"
                        className="flex gap-2 py-1 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange"
                        onClick={() => onClickAdd(order, index)}
                      >
                        <p className="mt-0.5">Status Change</p>
                      </button>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        type="button"
                        onClick={() => handleViewInvoice(order)}
                        className="text-blue-500 hover:underline flex items-center"
                      >
                        View Invoice
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        // Card view with mobile responsiveness
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {filteredOrders &&
            filteredOrders.map((order, index) => (
              <div
                key={index}
                className="bg-white shadow rounded-lg p-4 border border-gray-200 w-full"
              >
                <p className="text-lg font-bold">{order.transactionReference}</p>
                <p className="text-sm text-gray-600">
                  {order.contactInfo.firstName} - {order.contactInfo.phoneNumber}
                </p>
                <p className="text-sm text-gray-600">
                  £ {order.transactionAmount ? order.transactionAmount.toFixed(2) : "0.00"}
                </p>
                <p className="text-sm text-gray-600">
                  <Moment format="YYYY/MM/DD HH:mm">{order.orderDate}</Moment>
                </p>
                <p className="text-sm">{getStatus(order.orderStatus)}</p>
                <div className="flex justify-between items-center mt-4">
                  <button
                    type="button"
                    className="py-1 px-2 text-sm font-medium text-white-full bg-green-dark rounded-md"
                    onClick={() => onClickAdd(order, index)}
                  >
                    Status Change
                  </button>
                  <button
                    type="button"
                    onClick={() => handleViewInvoice(order)}
                    className="text-blue-500 hover:underline"
                  >
                    View Invoice
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Drawer Components */}
      {openDrawer && (
        <InvoiceDrawer
          openDrawer={openDrawer}
          onClose={() => setOpenDrawer(false)}
          orderRef={orderReference}
        />
      )}

      {openStatusDrawer && (
        <OrderStatusDrawer
          orderStatus={orderInfo?.orderStatus === 0 ? 5 : orderInfo?.orderStatus}
          orderId={orderId}
          openDrawer={openStatusDrawer}
          onClose={(e: any) => {
            onCollectOrderStatus(e)
            setOpenStatusDrawer(false);
          }}
        />
      )}
    </div>
  );
};

export default OrderList;
