import { useEffect, useState } from "react";
import { getStaffGrantPermissions, postStaffeGrantPermissions } from "../../../store/slice/staff-slice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function StaffDrawer({ staffId, openDrawer, onClose }: { staffId?: any, openDrawer: boolean, onClose: any }) {
    const [permissionList, setPermissionList] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { staffeGrantPermissions } = useSelector((state: any) => state.staff);
    const [isChecked, setIsChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFullAccess, setIsFullAccess] = useState<boolean>(true)


 
    useEffect(() => {
        if (staffeGrantPermissions != null && staffeGrantPermissions.length > 0) {
            let permissions = staffeGrantPermissions.map((x: any) => { return x.claimValue });
            setPermissionList(permissions);
        }
    }, [staffeGrantPermissions]);

    useEffect(() => {
        if (staffId != null && staffId > 0) {
            setPermissionList([]);
            dispatch(getStaffGrantPermissions(staffId));
        }
    }, [staffId]);

    const handleCheckboxChange = (staffId: any) => {
        setIsChecked(prevState => !prevState);
        let permission = "Permissions.FullAccess";

        if (!permissionList.includes(permission)) {
            permissionList.push(permission)
            setIsFullAccess(true);
            // console.log(permission);
        } else {
            const index = permissionList.indexOf(permission);
            permissionList.splice(index, 1);
            // console.log(index);
            // setIsFullAccess(false);
        }

        // console.log(permissionList);
        // console.log(isChecked);
        setPermissionList(permissionList);
    };

    const handleSubmitPermissions = () => {
        setIsLoading(true);
        let data = {
            data: {
                staffId: staffId,
                IsFullAccess: isFullAccess,
            },
        };
        // console.log(data)
        dispatch(postStaffeGrantPermissions(data))
            .unwrap()
            .then(() => {
            })
            .catch(() => {

            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            });
    };
    return (
        <>
            <div
                id="drawer-right-example"
                className={`fixed top-0 py-4 right-0 z-50 h-auto bottom-0  p-4 overflow-y-auto transition-transform ${openDrawer ? "translate-x-0 duration-500" : "translate-x-full duration-500"
                    } bg-white-full w-[25%] drop-shadow-xl transition-transform duration-500 `}
                aria-labelledby="drawer-right-label"
            >
                <nav className="justify-between p-2 border border-gray-200 rounded-sm sm:flex sm:px-5 bg-white-A700 " aria-label="Breadcrumb">
                    <div className="flex md:flex-col flex-row top-0  md:h-auto md:p-3 items-center w-full">
                        <ul className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0 gap-2.5">
                            <li>
                                <button
                                    className="text-white font-medium rounded-lg text-sm p-2.5"
                                    type="button"
                                    onClick={onClose}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                        className="fill-current"
                                        width="25"
                                        height="25"
                                        fill="red">

                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </button>
                            </li>
                            <li>
                                {/* <div className="flex flex-1 flex-col items-center justify-center max-w-[832px] w-full">
                                    <text
                                        className="md:text-2xl sm:text-[22px] text-[26px] text-blue_gray-900 w-auto"
                                    >
                                        GrantPermission
                                    </text>
                                </div> */}
                            </li>

                        </ul>
                    </div>
                </nav>
                <div className="mt-5 rounded-lg">
                    <div className="flex items-center mb-4">
                        <input id="default-checkbox" type="checkbox" value="" className="w-5 h-5 text-green-dark bg-gray-100  rounded focus:ring-green-dark dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={() => handleCheckboxChange(staffId)}
                            checked={permissionList.includes("Permissions.FullAccess")}
                        />
                        <label className="ms-2 text-sm font-medium text-gray-900">GrantPermission</label>
                    </div>
                    <div className="flex flex-row gap-2 mt-4 items-start justify-start w-[278px]">
                        <button onClick={onClose} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border border-gray-200">
                            <p>Cancel</p>
                        </button>
                        <button type="button" className="flex gap-3 p-2 px-5 text-sm font-medium text-white-full bg-green-dark border border-gray-200 hover:bg-orange hover:text-black rounded-md"
                            onClick={() => {
                                // setOpenModal_1(false);
                                handleSubmitPermissions();
                            }}>
                            <p>Save</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export { StaffDrawer };