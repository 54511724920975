import React, { useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { FRegister } from "../../store/slice/auth-slice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number, date, InferType } from 'yup';

const Register = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validationSchema = object().shape({
        Name: string()
            .required('company Name is required'),
        companyAddress: string()
            .required('company Address is required'),
        companyLogoUrl: string()
            .required('companyLogoUrl is required'),
        persionFirstName: string()
            .required('persionFirstName is required'),
        persionLastName: string()
            .required('persionLastName is required'),
        phoneNumber: string()
            .required('phoneNumber is required'),
        email: string()
            .required('Email is required')
            .email('Email is invalid'),
        password: string()
            .required('Password is required')
        //acceptTerms: bool().oneOf([true], 'Accept Terms is required')
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        // console.log(JSON.stringify(data, null, 2));
        dispatch(FRegister(data))
            .unwrap()
            .then(() => {
                navigate("/login");
            })
            .catch(() => {
                // setLoading(false);
            })
            .finally(() => {
                setTimeout(() => {
                }, 300);
            });
    };
    return (
        <div className="w-full bg-white-full px-32 py-10">
            <div className="flex justify-center items-center w-full">
                <form className="w-full flex flex-col justify-center items-center mx-auto gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex text-3xl font-medium text-black ">
                        <p>Register</p>
                    </div>
                    <div className="flex flex-col w-1/2 gap-3">
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Company Name
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm   block w-full p-2.5 focus:ring-green-dark dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.Name ? 'is-invalid' : ''}`} placeholder="Company Name"
                                {...register('Name')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.Name?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Company Address
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.Name ? 'is-invalid' : ''}`} placeholder="Company Address" {...register('companyAddress')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.companyAddress?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Logo URL
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.companyLogoUrl ? 'is-invalid' : ''}`} placeholder="Image Url" {...register('companyLogoUrl')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.companyLogoUrl?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                First Name
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.persionFirstName ? 'is-invalid' : ''}`} placeholder="First Name" {...register('persionFirstName')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.persionFirstName?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Last Name
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.persionLastName ? 'is-invalid' : ''}`} placeholder="Last Name" {...register('persionLastName')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.persionLastName?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Phone Number
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.phoneNumber ? 'is-invalid' : ''}`} placeholder="Eg:077356892" {...register('phoneNumber')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.phoneNumber?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                E-mail
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.email ? 'is-invalid' : ''}`} placeholder="example@gmail.com" {...register('email')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.email?.message?.toString()}</div>
                        </div>
                        <div className="relative text-left">
                            <label className="block mb-1">
                                Password
                            </label>
                            <input type="text" className={`bg-gray-50 border border-green-dark text-black text-sm rounded-sm focus:ring-green-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.password ? 'is-invalid' : ''}`} placeholder="*******" {...register('password')} />
                            <div className="font-medium text-xs text-red-600" >{errors?.password?.message?.toString()}</div>
                        </div>
                        {/* <div className="flex items-center">
                            <input id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-black bg-white-full border-green-dark focus:ring-0 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Checked state</label>
                        </div>
                        <div className="flex items-center">
                            <input id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-black bg-white-full border-green-dark focus:ring-0 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Checked state</label>
                        </div> */}
                        <div className="flex justify-center items-start float-left">
                            <button type="submit" className="flex gap-3 py-3 w-60 p-3 px-5 me-2 text-sm font-medium text-white-full bg-green-dark justify-center rounded-lg border border-gray-200 hover:black hover:opacity-50">
                                <p>Register</p>
                            </button>
                        </div>
                        <div className="flex justify-center items-start float-left text-sm">
                            <p> Already have an accound? <a href="/login" className="text-red-700 text-sm hover:underline ">Log in</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Register;