import { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../components/Dropdown";
import { AddContact, DeleteContact, HeaderButton, MainFilter } from "../../components";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { CiFilter } from "react-icons/ci";
import { fetchContactData } from "../../store/slice/contact-slice";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Loading from "../../components/Loading/loading";
import { toast } from "react-toastify";

const Contact = () => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const { contactList, status, error } = useSelector((state: any) => state.contact);
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = 5;
    const [isOpen, setIsOpen] = useState(false);
    const [delId, setDelId] = useState(null);
    const [contact, setContact] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openDelModal, setOpenDelModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const today = new Date();

    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });
    const toggleAddModal = () => {
        setOpenModal(!openModal);
    };
    const toggleUpdateModal = () => {
        setOpenUpdateModal(!openUpdateModal);
    };
    const toggleDeleteModal = () => {
        setOpenDelModal(!openDelModal);
    };
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchContactData())
            .catch((error)=>{
                toast.error(error);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);

    useEffect(()=>{
        if (status === 'failed') {
            toast.error(error);
        }
    },[status])

    const pageCount = contactList ? Math.ceil(contactList.length / perPage) : 0;
    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };

    const startIndex = currentPage * perPage;
    const endIndex = startIndex + perPage;

    const paginatedContactList = contactList ? contactList.slice(startIndex, endIndex) : [];
    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target?.value || '';
        setSearchTerm(newValue);
    };

    const filteredContactList = paginatedContactList.filter((contact: any) => {
        const { firstName, lastName, phoneNumber, address, id } = contact;
        const searchValue = searchTerm.toLowerCase();
        const idString = typeof id === 'string' ? id : String(id);
        return (
            (firstName && firstName.toLowerCase().includes(searchValue)) ||
            (lastName && lastName.toLowerCase().includes(searchValue)) ||
            (phoneNumber && phoneNumber.includes(searchValue)) ||
            (address && address.toLowerCase().includes(searchValue)) ||
            (idString && idString.toLowerCase().includes(searchValue))
        );

    });

    const filterContactsByDate = (contacts: any[]) => {
        if (!selectedFilter) return contacts;
        const now = new Date();
        let filteredContacts = contacts;
        if (selectedFilter === 'Last Week') {
            const lastWeek = new Date(now.setDate(now.getDate() - 7));
            filteredContacts = contacts.filter((contact) => new Date(contact.date) >= lastWeek);
        } else if (selectedFilter === 'Last Month') {
            const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
            filteredContacts = contacts.filter((contact) => new Date(contact.date) >= lastMonth);
        } else if (selectedFilter === 'Last year') {
            const lastYear = new Date(now.setFullYear(now.getFullYear() - 1));
            filteredContacts = contacts.filter((contact) => new Date(contact.date) >= lastYear);
        }
        return filteredContacts;
    };
    const finalContactList = filterContactsByDate(filteredContactList);

    // filter data
    const data = [
        { name: 'Last Week' },
        { name: 'Last Month' },
        { name: 'Last year' },
    ];

    const handleFilterSelect = (filter: string) => {
        setSelectedFilter(filter);
    };


    return (
        <>
            <div className="flex flex-col justify-start gap-3 items-start w-full h-full">
                <div className="w-full flex-col justify-start items-start flex gap-1">
                    <h1 className="text-2xl">Contacts</h1>
                    <p className="text-gray-400 text-sm">{formattedDate}</p>
                    <div className="flex flex-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5">
                        <div className="flex flex-row gap-2" ref={dropdownRef}>
                            <div className="flex gap-3 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md hover:bg-gray-100 placeholder-text-sm">
                                <div className="relative">
                                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="text" className="block ps-8 text-sm border text-gray-900 rounded-md " placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchTermChange} />
                                </div>                                
                            </div>
                            <div className="flex h-10 ">
                            <MainFilter data={data} onFilterSelect={handleFilterSelect} />
                            </div>
                        </div>
                        <div>
                            <HeaderButton buttonName="Contact" isAdd={true} onClickAdd={toggleAddModal}></HeaderButton>
                        </div>
                    </div>
                </div>

                <div className="w-full h-full overflow-auto">
                    <div className="relative">
                    {(isLoading) && <Loading></Loading>}
                        <table className="min-w-full border table-auto text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-green-dark bg-gray-100 rounded focus:ring-green-dark dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Contact Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {finalContactList?.map((contact: any) => (
                                    <tr key={contact.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-table-search-3" type="checkbox" className="w-4 h-4 text-green-dark bg-gray-100 rounded focus:ring-green-dark dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <div className="flex items-center">
                                                <img className="w-10 h-10 rounded-full" src={contact.image || "https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"} alt="Contact image" />
                                                <div className="ps-3">
                                                    <div className="text-base font-semibold">{contact.firstName} {contact.lastName}</div>
                                                    <div className="font-normal text-gray-500">{contact.email}</div>
                                                </div>
                                            </div>
                                        </th>
                                        <td className="px-6 py-4">
                                            {contact.phoneNumber}
                                        </td>
                                        <td className="flex items-center px-6 py-4 space-x-2">
                                            <button className="p-2 bg-transparent border-2 rounded-md text-green-dark" type="button" onClick={() => { setContact(contact); toggleUpdateModal(); }}>
                                                <FaRegEdit className="w-5 h-5" />
                                            </button>
                                            <button className="p-2 bg-transparent border-2 rounded-md text-red-600" type="button" onClick={() => { setDelId(contact.id); toggleDeleteModal(); }}>
                                                <MdDeleteOutline className="w-5 h-5" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {finalContactList.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 rounded-md w-full">
                                <p className="text-red-800">No contacts found.</p>
                            </div>
                        )}
                    </div>
                </div>

                <AddContact openDrawer={openModal} Close={toggleAddModal} modalType="Add" />
                <AddContact openDrawer={openUpdateModal} Close={toggleUpdateModal} modalType="Edit" contact={contact} />
                <DeleteContact openDelModal={openDelModal} delId={delId} Close={toggleDeleteModal} />
            </div>
        </>
    )
}

export default Contact;
