import axios, { AxiosError } from "axios";
import { LocalStorageService } from "./localstorage.service";
import AuthService from "./auth.service";
import { ErrorResult } from "../store/model/errorResult";

//const BACKEND_BASE_URL = process.env.REACT_APP_API_URL;
const keyvalue = process.env.REACT_APP_LOCAL_KEY || 'personalsuite-userinfo';
const axiosInstance = axios.create({
 //  baseURL: "https://localhost:7040/api/",
  baseURL: "https://api.clean-laundry.co.uk/api/",
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    const userinfo = LocalStorageService.getItem<any>(keyvalue)
    if (userinfo) {
      config.headers['Authorization'] = 'Bearer ' + userinfo.token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(response => {
  return response
},
 (error: AxiosError) => {
  if(error.response!=null){
    if(error.response.status ===401){
      AuthService.logout();
      window.location.href = '/login';
    }
    let result = error.response.data as ErrorResult;
    return Promise.reject(result);
  }else{
    var errorResult: ErrorResult = {
        messages : ['Please Contact System Adminstrator'],
        errorId: '',
        source: '',
        statusCode: 500,
        supportMessage: '',
        exception: ''
    }
    return Promise.reject(errorResult);
  }
}
)



export default axiosInstance;