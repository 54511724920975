// Layouts
import AnonymousLayout from "../layouts/AnonymousLayout";
import MainLayout from "../layouts/MainLayout";
import { renderRoutes } from "./generate-routes";

// Pages
import Login from "../pages/Login";
import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import ResetPass from "../pages/ResetPass/ResetPass";
import ResetPassEmail from "../pages/ResetPass/ResetPassEmail";
import Test from "../pages/Dashboard/test";
import Staff from "../pages/Staff";
import Contact from "../pages/Contacts";
import Calendar from "../pages/Calender";
import Orders from "../pages/Orders";
import Reports from "../pages/Reports/Index";
import Services from "../pages/Services";
import AddServicePage from "../pages/Services/AddService/AddServicePage";
import AdminPostcode from "../pages/AdminPostcode"; // Import the AdminPostcode page


// settings
import Role from "../pages/Settings/Role";
import Branch from "../pages/Settings/Branch";
import ServiceTypes from "../pages/Settings/ServiceTypes";
import ProfilePage from "../pages/Settings/Profile";

export const routes = [
  {
    layout: AnonymousLayout,
    isPublic: true,
    routes: [
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        path: '/login',
        isPublic: true,
      },
      {
        name: 'register',
        title: 'Register page',
        component: Register,
        path: '/register',
        isPublic: true,
      },
      {
        name: 'resetPass',
        title: 'resetPass page',
        component: ResetPass,
        path: '/resetpassword2',
        isPublic: true,
      },
      {
        name: 'resetPass',
        title: 'resetPass page',
        component: ResetPassEmail,
        path: '/resetpassword',
        isPublic: true,
      }
    ]
  },
  {
    layout: MainLayout,
    isPublic: false,
    routes: [
      {
        name: 'dashboard',
        title: 'dashboard page',
        component: Dashboard,
        path: '/'
      },
      {
        name: 'dashboard',
        title: 'dashboard page',
        component: Dashboard,
        path: '/dashboard'
      },
      {
        name: 'staff',
        title: 'staff page',
        component: Staff,
        path: '/staff'
      },
      {
        name: 'contact',
        title: 'contact page',
        component: Contact,
        path: '/contact'
      },
      {
        name: 'calendar',
        title: 'calendar page',
        component: Calendar,
        path: '/calendar'
      },
      {
        name: 'orders',
        title: 'orders page',
        component: Orders,
        path: '/orders'
      },
      {
        name: 'reports',
        title: 'reports page',
        component: Reports,
        path: '/reports'
      },
      {
        name: 'services',
        title: 'services page',
        component: Services,
        path: '/services',
        routes: [
          {
            name: 'addServicePage',
            title: 'addServicePage page',
            component: AddServicePage,
            path: '/services/addservicepage',
          },
        ]
      },
      {
        name: 'settings',
        title: 'settings page',
        routes: [
          {
            name: 'role',
            title: 'role page',
            component: Role,
            path: '/settings/role',
          },
          {
            name: 'branch',
            title: 'branch page',
            component: Branch,
            path: '/settings/branch',
          },
          {
            name: 'servicetypes',
            title: 'servicetypes page',
            component: ServiceTypes,
            path: '/settings/servicetypes',
          },
        ]
      },
      {
        name: 'postcodes',
        title: 'postcodes page',
        component: AdminPostcode,
        path: '/postcodes'
      },
      {
        name: 'profile',
        title: 'Profile page',
        component: ProfilePage,
        path: '/profile'
      },
      {
        name: 'test',
        title: 'dashboard page',
        component: Test,
        path: '/test'
      },
    ]
  }
];

export const Routes = renderRoutes(routes);
