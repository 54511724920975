import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import RoleService from "../../services/role.service";


const postDataFailure = createAction<string>("Role/postDataFailure");
const getDataFailure = createAction<string>("Role/getDataFailure");
const deleteDataFailure = createAction<string>("Role/deleteDataFailure");

type NewRole = {
    name: string;
    id: number;
};

type UpdatePageRole = {
    roleId: number;
    permissions: string[];
};

interface ApiResponse {
    success: boolean;
    message: string;
    roleList?: NewRole[];
    PageRole: UpdatePageRole[];
    rolePermissions: [];
};

type RoleApiState = {
    status: "idle" | "loading" | "failed";
    error: string | null;
    roleList: NewRole[] | null;
    PageRole: UpdatePageRole[] | null;
    rolePermissions: [] | null;
};

const initialState: RoleApiState = {
    status: "idle",
    error: null,
    roleList: null,
    PageRole: null,
    rolePermissions: null,
};

export const addRole = createAsyncThunk(
    "addRole",
    async (data: NewRole, thunkAPI) => {
        try {
            const response = await RoleService.addRoleData(data);
            const resData = response.data;
            return resData;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const getRole = createAsyncThunk(
    "getRole",
    async (_, thunkAPI) => {
        try {
            const response = await RoleService.getRoleData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getRolePageResource = createAsyncThunk(
    "getRolePageResource",
    async (_, thunkAPI) => {
        try {
            const response = await RoleService.getRolePageResourceData();
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getrolePermissions = createAsyncThunk(
    "getrolePermissions",
    async (id:number, thunkAPI) => {
        try {
            const response = await RoleService.getrolePermissionsData(id);
            return response.data;
        } catch (error: any) {
             return thunkAPI.rejectWithValue(error);
        }
    }
);


export const updateRole = createAsyncThunk(
    "updateRole",
    async ({ id, data }: { id: any; data: NewRole }, thunkAPI) => {
        try {
            const response = await RoleService.updateRoleData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updatePageRole = createAsyncThunk(
    "updatePageRole",
    async ({ id, data }: { id: any; data: UpdatePageRole }, thunkAPI) => {
        try {
            const response = await RoleService.updatePageRoleData(id, data);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(postDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteRole = createAsyncThunk(
    "deleteRole",
    async (id: any, thunkAPI) => {
        try {
            const response = await RoleService.deleteRoleData(id);
            return response.data;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(deleteDataFailure(message));
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const roleSlice: any = createSlice({
    name: "role",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //role get--------->
            .addCase(getRole.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getRole.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.roleList = action.payload || [];
                }
            )
            .addCase(getRole.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch role data";
            })

            // getRolePageResource---------->
            .addCase(getRolePageResource.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getRolePageResource.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.PageRole = action.payload || [];
                }
            )
            .addCase(getRolePageResource.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch RolePageResource";
            })

            // getrolePermissions---------->
            .addCase(getrolePermissions.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getrolePermissions.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    state.rolePermissions = action.payload || [];
                }
            )
            .addCase(getrolePermissions.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch RolePageResource";
            })

            // post branch------------------->
            .addCase(addRole.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                addRole.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.status = "idle";
                }
            )
            .addCase(addRole.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Role Add failed";
            })

            // update branch --------------------->
            .addCase(updateRole.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                updateRole.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.PageRole = action.payload || [];
                    }
                }
            )
            .addCase(updateRole.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update Role data";
            })

            // update PageRole --------------------->
            .addCase(updatePageRole.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                updatePageRole.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.status = "idle";
                    if (action.payload != null) {
                        state.PageRole = action.payload || [];
                    }
                }
            )
            .addCase(updatePageRole.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to update Role data";
            })
            //  delete branch --------------------------->
            .addCase(deleteRole.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })

            .addCase(deleteRole.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = "idle";
            })

            .addCase(deleteRole.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to delete Role";
            })
    },
});

export default roleSlice.reducer;
