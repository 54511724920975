import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { logout } from "../../store/slice/auth-slice";
import { CustomIcons } from "../Icons";

interface SideBarProps {
    isMobileOpen: boolean;
}

function SideBar({ isMobileOpen }: SideBarProps) {
    const location = useLocation();
    const { pathname } = location;
    const [isOpenSetting, setIsOpenSetting] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const toggleDropdownSetting = () => {
        setIsOpenSetting(!isOpenSetting);
    };

    const handleLogout = () => {
        try {
            dispatch(logout()).unwrap();
            navigate("/login");
        } catch (e) {
            console.error(e);
        }
    };

    return (
      <div
        className={`!sticky px-4.5 w-[220px] gap-3 bg-light-blue flex flex-col h-screen justify-start overflow-hidden md:px-5 top-0 ${
          !isMobileOpen ? "md:hidden " : ""
        }`}
      >
        <div className="flex flex-col gap-3 items-start justify-start bg-light-blue mt-5 mx-auto w-[172px] h-fit">
          <div className="flex flex-col items-center justify-center p-1 w-full bg-light-blue">
            <div className="justify-center">
              <img
                className="rounded-md w-30 h-30 drop-shadow-lg"
                src="/authsuite-high-resolution-logo-transparent.png" // Make sure to place your logo in the public folder
                alt="Logo"
              />
            </div>
          </div>
        </div>
        <hr className="bg-white-full w-full" />
        <div className="flex flex-col items-start justify-start bg-light-blue pb-[35px] mx-4 w-[172px] h-full overflow-y-auto">
          <div className="flex flex-col gap-3 mt-6 items-start justify-start w-full">
            <ul className="items-start font-medium justify-start w-full space-y-2">
              <li>
                <NavLink
                  to="/dashboard"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("/dashboard") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons
                      iconName="dashboard"
                      className="fill-darkblue"
                    />
                    <span className="text-base w-auto">Dashboard</span>
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/orders"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("/orders") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons iconName="order" className="fill-darkblue" />
                    <span className="text-base w-auto">Orders</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("/contact") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons iconName="contact" className="w-6 h-6" />
                    <span className="text-[15px] w-auto">Customers</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("services") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons iconName="service" className="w-6 h-6" />
                    <span className="text-[15px] w-auto">Services</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reports"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("/reports") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons iconName="report" className="w-6 h-6" />
                    <span className="text-[15px] w-auto">Reports</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/postcodes"
                  className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-darkblue ${
                    pathname.includes("/postcodes") &&
                    "!bg-white-full !text-darkblue"
                  }`}
                >
                  <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                    <CustomIcons iconName="postcode" className="w-6 h-6" />
                    <span className="text-[15px] w-auto">Postcodes</span>
                  </div>
                </NavLink>
              </li>
              {/* <li className="transition-all duration-700">
                            <div
                                className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-light-blue ${pathname.includes('/settings') ? '!bg-white-full !text-light-blue' : ''}`}
                                onClick={toggleDropdownSetting}
                            >
                            </div>
                            <ul className={`ml-6 mt-1 space-y-1 bg-light-blue transition-all duration-700 ease-in-out overflow-hidden ${isOpenSetting ? 'max-h-40 opacity-100 visible' : 'max-h-0 opacity-0 invisible'}`}>
                                <li className="cursor-pointer">
                                    <NavLink to="/profile" className={`flex flex-col items-start justify-start rounded-sm w-full py-1 font-sans text-black-full cursor-pointer hover:bg-white-full hover:text-light-blue ${pathname.includes('/settings/profile') && '!text-orange'}`}>
                                        <div className="flex flex-row gap-3.5 px-3 rounded-lg w-full">
                                            <span className="text-[13px] w-auto">Profile</span>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li> */}
            </ul>
          </div>
        </div>
        <hr className="bg-white-full w-full" />
        <div className="mt-auto w-full text-black-full cursor-pointer font-bold bg-light-blue rounded-lg font-sans">
          <div className="flex items-center justify-between gap-2 p-2">
            <div className="flex flex-row gap-2 px-3 rounded-lg w-full">
              <NavLink to="/profile" className='flex gap-2'>
                <a href="!#">
                  <img
                    className="w-8 h-8 rounded-full"
                    src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                    alt="Jese Leos"
                  />
                </a>
                <FontAwesomeIcon
                  className="w-3 h-3 mt-2.5 rotate-180"
                  icon={faAngleDown}
                />
              </NavLink>
            </div>
            <div>
              <button className="p-1" onClick={handleLogout}>
                <FontAwesomeIcon
                  className="w-5 h-5 rotate-180"
                  icon={faSignOut}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export { SideBar };
