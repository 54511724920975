import { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../components/Dropdown";
import { AddServiceType, DeleteServiceType, HeaderButton } from "../../components";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { getBookingServiceData } from "../../store/slice/bookingservice-slice";
import { CiFilter } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Loading from "../../components/Loading/loading";


const Services = () => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const { bookingServiceList } = useSelector((state: any) => state.bookingService);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredServiceList, setFilteredServiceList] = useState<any[]>();
    // const perPage = 5;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [ServiceTypeId, setServiceTypeDelId] = useState(null);
    const [ServiceType, setServiceType] = useState<any[]>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openDelModal, setOpenDelModal] = useState(false);
    const today = new Date();

    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });
    const toggleAddModal = () => {
        setOpenModal(!openModal);
    };
    const toggleUpdateModal = () => {
        setOpenUpdateModal(!openUpdateModal);
    };
    const toggleDeleteModal = () => {
        setOpenDelModal(!openDelModal);
    };
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(getBookingServiceData()).finally(()=>{
                setIsLoading(false);
            });

            effectSeviceCall.current = true;
        }
    }, [dispatch]);


    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target?.value || '';
        setSearchTerm(newValue);
    };

    useEffect(()=>{
        if(bookingServiceList!=null && bookingServiceList.length > 0){
            const filteredList = bookingServiceList && bookingServiceList.filter((bookingService: any) => {
                const { serviceName, Location, id } = bookingService;
                const searchValue = searchTerm.toLowerCase();
                const idString = typeof id === 'string' ? id : String(id);
                return (
                    (serviceName && serviceName.toLowerCase().includes(searchValue)) ||
                    (Location && Location.toLowerCase().includes(searchValue)) ||
                    (idString && idString.toLowerCase().includes(searchValue))
                );
        
            });
            setFilteredServiceList(filteredList);

        }

    }, [bookingServiceList])


    return (
        <>
            <div className="flex flex-col justify-start gap-3 items-start w-full">
                <div className="w-full flex-col justify-start items-start flex gap-1">
                    <h1 className="text-2xl">Services</h1>
                    <p className="text-gray-400 text-sm">{formattedDate}</p>
                    <div className="flex fles-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5 ">
                        <div className="flex fles-row gap-2" ref={dropdownRef}>
                            <div className="flex gap-3 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md hover:bg-gray-100 placeholder-text-sm">
                                <div className="relative">
                                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="text" className="block ps-8 text-sm border text-gray-900 rounded-md h-10" placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchTermChange} />
                                </div>
                            </div>
                            <button onClick={() => setIsOpen(!isOpen)} type="button" className="flex gap-2 p-2 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 hover:bg-gray-100">
                                <div className="fill-black">
                                    <CiFilter className="w-6 h-6" />
                                </div>
                                <p className="mt-0.5">Filters</p>
                            </button>
                            {isOpen && (
                                <div className=" left-72 ml-4 border absolute mt-8 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 bg-white-full">
                                    <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="">
                            <HeaderButton buttonName="Service" isAdd={true} onClickAdd={toggleAddModal} link={'/services/addservicepage'}></HeaderButton>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="md:flex md:flex-col grid grid-cols-3 justify-center items-center gap-3  w-full py-2">
                    {(isLoading) && <Loading></Loading>}
                        {filteredServiceList?.map((bookingService: any) => (
                            <div key={bookingService.id} className="relative border rounded-md col-span-1 w-full md:flex md:flex-col">
                                <div  className="bg-white-A700  border border-indigo-50 border-solid flex flex-1 flex-col gap-[9px] h-auto md:h-auto pb-[20px] pl-[17px] pr-[13px] pt-[13px] rounded w-full">
                               
                                <div className="flex flex-col items-start justify-between w-[254px]">
                                        <img
                                            className="h-28 w-[220px] md:w-full  rounded-md"
                                            src={bookingService.imageUrl}
                                            alt="arrowright"
                                        />
                                    </div>
                                  
                                    <div className="flex flex-col items-start justify-between w-[254px]">
                                        {bookingService.serviceName || "Service Name Not Available"}
                                    </div>
                                    <div className="flex flex-col items-start justify-start pb-[0.03px] pr-[7.67px] w-auto">

                                        Price of this lesson is {bookingService.amount} Rs
                                    </div>
                                    <div className="flex flex-row gap-2 items-start justify-start pr-[7.67px] w-auto">
                                        {/* <Link to={`/updateservice?id=${bookingService.serviceId}`}> */}
                                        <button
                                            className="bg-transparent p-2 border-2 rounded-md w-auto text-green-dark"
                                            type="button"
                                            onClick={() => { toggleUpdateModal(); }}
                                        >
                                            <FaRegEdit className="w-5 h-5" />
                                        </button>
                                        {/* </Link> */}
                                        <button
                                            className=" bg-transparent p-2 border-2 rounded-md w-auto text-red-600"
                                            type="button"
                                            onClick={() => { toggleDeleteModal(); }}
                                        >
                                            <MdDeleteOutline className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {
                            (openModal) &&
                            <AddServiceType openDrawer={openModal} Close={toggleAddModal} modalType="Add" />
                        }

                        {
                            (openUpdateModal) &&
                            <AddServiceType openDrawer={openUpdateModal} Close={toggleUpdateModal} modalType="Edit" serviceType={ServiceType} />
                        }

                        {
                            (openDelModal) &&
                            <DeleteServiceType openDelModal={openDelModal} delId={ServiceTypeId} Close={toggleDeleteModal} />
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Services;