import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";

const getAllTransaction = async () => {
  try {
    const response = await axiosInstance.get("transaction");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getDashboardInfo = async (data: any) => {
  try {
    const response = await axiosInstance.post("transaction/dashboard", data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};


const getInvoiceInformation = async (reference: any) => {
  try {
    const response = await axiosInstance.get("transaction/admininvoice/" + reference);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateDeliveryInfo = async (id: number,data: any) => {
  try {
    const response = await axiosInstance.put("transaction/status/" + id , data);
    return response;
  }catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const getDeliveryInfoList = async (id: number) => {
  try {
    const response = await axiosInstance.get("transaction/deliveryinfo/" + id);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const TransactionService = {
  getAllTransaction,
  getDashboardInfo,
  getInvoiceInformation,
  updateDeliveryInfo,
  getDeliveryInfoList
}

export default TransactionService;