import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchInvoiceInfo } from "../../../store/slice/order-slice";

interface Address {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    email?: string;
    phone?: string;
  }

  
function InvoiceDrawer({ orderRef, openDrawer, onClose }: { orderRef?: any, openDrawer: boolean, onClose: any }) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {invoiceInfo} = useSelector((state: any) => state.order);
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState<any>();
    const [billingAddress, setBillingAddress] = useState<Address | null>();
    const [contactInfo, setContactInfo] = useState<any | null>();
    const [productItem, setProductItem] = useState<any[]>();


    useEffect(() => {
       if(invoiceInfo!=null){
            let address = invoiceInfo.invoiceDto.billingAddress.split(',');
            if(address!=null){
              let add: Address = {
                addressLine1 :address[0],
                addressLine2 :address[1],
                city :address[2],
                state :address[3],
                zipCode :address[4]
              }
              setBillingAddress(add)
            }
            setInvoiceData(invoiceInfo.invoiceDto)
            setContactInfo(invoiceInfo.contactInfo)
            setProductItem(invoiceInfo.invoiceDto.invoiceItems);
       }
    }, [invoiceInfo]);


    useEffect(() => {
        if(orderRef!=null){
        setIsLoading(true)
         dispatch(fetchInvoiceInfo(orderRef)).finally(()=>{
            setIsLoading(false);
         });
        }
     }, [orderRef]);


    return (
        <>
            <div id="drawer-right-example" className={`fixed top-0 py-4 right-0 z-50 h-auto bottom-0  p-4 overflow-y-auto transition-transform ${openDrawer ? "translate-x-0 duration-500" : "translate-x-full duration-500"
                    } bg-white-full w-[50%] drop-shadow-xl transition-transform duration-500 `}
                aria-labelledby="drawer-right-label">
                <nav className="justify-between p-2 border border-gray-200 rounded-sm sm:flex sm:px-5 bg-white-A700 " aria-label="Breadcrumb">
                    <div className="flex md:flex-col flex-row top-0  md:h-auto md:p-3 items-center w-full">
                        <ul className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0 gap-2.5">
                            <li>
                                <button className="text-white font-medium rounded-lg text-sm p-2.5" type="button" onClick={onClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                        className="fill-current"
                                        width="25"
                                        height="25"
                                        fill="red">

                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </button>
                            </li>
                            <li>
                                <div className="flex flex-1 flex-col items-center justify-center max-w-[832px] w-full">
                                    <text className="md:text-2xl sm:text-[22px] text-[26px] text-blue_gray-900 w-auto">
                                       Invoice 
                                    </text>
                                </div>
                            </li>

                        </ul>
                    </div>
                </nav>
                <div className="mt-2 rounded-lg">
                    <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">

                    <div className="max-w-4xl mx-auto p-4 bg-[#fdfefe] shadow-lg mt-4 rounded-md">
                            <header className="flex justify-between items-center border-b pb-4 bg-[#fdfefe]">
                                <div>
                                <h1 className="text-3xl font-bold text-blue-600">INVOICE</h1>
                                <img src="/authsuite-high-resolution-logo-transparent.png" alt="Company Logo" width={150} height={50} />
                                </div>
                                <div className="text-right">
                                <h2 className="text-xl font-bold">Invoice # 000 {invoiceData?.id}</h2>
                                <p>Invoice Date: {invoiceData?.invoiceDate}</p>
                                </div>
                            </header>
                            <section className="flex justify-between mt-8 mb-8">
                                {(contactInfo) && 
                                    <div>
                                    <h3 className="font-bold">Customer Info:</h3>
                                    <p>{contactInfo?.firstName}</p>
                                    <p>Email: {contactInfo?.email}</p>
                                    <p>Phone: {contactInfo?.phoneNumber}</p>
                                </div>
                            }

                                {billingAddress && 
                                    <div>
                                    <h3 className="font-bold">Collection Address:</h3>
                                    <p>{billingAddress.addressLine1},</p>
                                    <p>{billingAddress.addressLine2},</p>
                                    <p>{billingAddress.city},</p>
                                    <p>{billingAddress.state},</p>
                                    <p>{billingAddress.zipCode}.</p>

                                    </div>
                                    }
                                </section>
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-sm border">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr className="bg-gray-200">
                                    <th className="border px-4 py-2">No</th>
                                    <th className="border px-4 py-2">PRODUCT</th>
                                    <th className="border px-4 py-2">QUANTITY</th>
                                    <th className="border px-4 py-2">PRICE</th>
                                    <th className="border px-4 py-2">TOTAL PRICE</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {productItem  && productItem.map((item: any, index: number) => (
                                    <tr key={index} className="text-center">
                                        <td className="border px-4 py-2">{index+1}</td>
                                        <td className="border px-4 py-2">{item.productName}</td>
                                        <td className="border px-4 py-2">{1}</td>
                                        <td className="border px-4 py-2">£ {item.unitPrice  ? item.unitPrice.toFixed(2) : '0.00' }</td>
                                        <td className="border px-4 py-2">£ {item.totalPrice ? item.totalPrice.toFixed(2) : '0.00' }</td>
                                    </tr>   
                                    ))}
                                </tbody>
                            </table>
                            <footer className="mt-8">
                                {/* <p className="font-bold">Payment method: Card Payment</p> */}
                                {(invoiceData) &&
                                  <div className="text-right">
                                  {(invoiceData.deliveryCharge!=null && invoiceData.deliveryCharge > 0) &&
                                  <p className="font-bold"> Delivery Charge: £ {invoiceData.deliveryCharge ? invoiceData.deliveryCharge.toFixed(2) : '0.00'}</p>
                                  }

                                  <p className="font-bold text-2xl"> Total: £ {invoiceData.totalAmount ? invoiceData.totalAmount.toFixed(2) : '0.00'}</p>
                              </div>
                                }
                              
                                </footer>
                            </div>
               
                    </div>
                  
                </div>
            </div>
        </>
    );
}
export { InvoiceDrawer };