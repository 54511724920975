import { useEffect, useState } from "react";
import { getRolePageResource, getrolePermissions, updatePageRole } from "../../../store/slice/role-slice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function RoleDrawer({ role, roleName, openDrawer, onClose }: { role?: any, roleName: any, openDrawer: boolean, onClose: any }) {
    const [permissionList, setPermissionList] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { PageRole } = useSelector((state: any) => state.role);
    const { rolePermissions } = useSelector((state: any) => state.role);
    const [isChecked, setIsChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        dispatch(getRolePageResource());
    }, [dispatch]);

    useEffect(() => {
        if (rolePermissions != null && rolePermissions.length > 0) {
            let permissions = rolePermissions.map((x: any) => { return x.claimValue });
            setPermissionList(permissions);
        }
    }, [rolePermissions]);

    useEffect(() => {
        if (role != null && role > 0) {
            setPermissionList([]);
            dispatch(getrolePermissions(role));
        }
    }, [role]);

    const handleCheckboxChange = (PageRole: any, permission: any) => {
        setIsChecked(prevState => !prevState);
        let permissionFormat = "Permissions." + PageRole + "." + permission;

        if (!permissionList.includes(permissionFormat)) {
            permissionList.push(permissionFormat)
            console.log(permissionFormat);
        } else {
            const index = permissionList.indexOf(permissionFormat);
            permissionList.splice(index, 1);
            console.log(index);
        }

        setPermissionList(permissionList);
    };

    const handleSubmitPermissions = () => {
        setIsLoading(true);
        let data = {
            id: role || 0,
            data: {
                roleId: role || 0,
                permissions: permissionList,
            }
        };
        console.log(data)
        dispatch(updatePageRole(data))
            .unwrap()
            .then(() => {
            })
            .catch(() => {

            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            });
    };
    return (
        <>
            <div
                id="drawer-right-example"
                className={`fixed top-0 py-4 right-0 z-50 h-auto bottom-0  p-4 overflow-y-auto transition-transform ${openDrawer ? "translate-x-0 duration-500" : "translate-x-full duration-500"
                    } bg-white-full w-[50%] drop-shadow-xl transition-transform duration-500 `}
                aria-labelledby="drawer-right-label"
            >
                <nav className="justify-between p-2 border border-gray-200 rounded-sm sm:flex sm:px-5 bg-white-A700 " aria-label="Breadcrumb">
                    <div className="flex md:flex-col flex-row top-0  md:h-auto md:p-3 items-center w-full">
                        <ul className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0 gap-2.5">
                            <li>
                                <button
                                    className="text-white font-medium rounded-lg text-sm p-2.5"
                                    type="button"
                                    onClick={onClose}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                        className="fill-current"
                                        width="25"
                                        height="25"
                                        fill="red">

                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </button>
                            </li>
                            <li>
                                <div className="flex flex-1 flex-col items-center justify-center max-w-[832px] w-full">
                                    <text
                                        className="md:text-2xl sm:text-[22px] text-[26px] text-blue_gray-900 w-auto"
                                    >
                                        {roleName} Permissions
                                    </text>
                                </div>
                            </li>

                        </ul>
                    </div>
                </nav>
                <div className="mt-2 rounded-lg">
                    <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-sm border">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3">

                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        View
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Create
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Edit
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Delete
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Search
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Export
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Import
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {PageRole != null && PageRole.map((PageRole: any) => (
                                    <tr key={PageRole.id} className="bg-white hover:bg-gray-50">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {PageRole}
                                        </th>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-view`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'View')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.View`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-view`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-create`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Create`)}
                                                    onChange={() => handleCheckboxChange(PageRole, 'Create')}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-create`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-edit`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'Edit')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Edit`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-edit`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-delete`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'Delete')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Delete`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-delete`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-search`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'Search')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Search`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-search`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-export`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'Export')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Export`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-export`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 border-b">
                                            <div className="flex items-center">
                                                <input id={`checkbox-${PageRole.id}-import`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                    onChange={() => handleCheckboxChange(PageRole, 'Import')}
                                                    checked={permissionList.includes(`Permissions.${PageRole}.Import`)}
                                                />
                                                <label htmlFor={`checkbox-${PageRole.id}-import`} className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-row gap-2 mt-4 items-start justify-start w-[278px]">
                        <button onClick={onClose} type="button" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-black  hover:text-green-dark rounded-sm border border-gray-200">
                            <p>Cancel</p>
                        </button>
                        <button type="button" className="flex gap-3 py-1 p-3 px-5 me-2 mb-2 text-sm font-medium text-white-full bg-green-dark rounded-sm border border-gray-200 hover:bg-green-light"
                            onClick={() => {
                                handleSubmitPermissions();
                            }}>
                            <p>Save</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export { RoleDrawer };