import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SelectBox } from '../SelectBox';
import { getBranch } from '../../store/slice/branch-slice';
import { getBookingServiceTypeData } from '../../store/slice/bookingServiceType-Slice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/hooks/redux-hooks';
import { Link } from 'react-router-dom';
import { boolean, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const AddServiceStepOne = ({ branchOptions, onSubmitStep, initialData }: any) => {
    const [errorMessageContact, setErrorMessageContact] = useState<string>('');
    const [seletedBranches, setSelectedBranches] = useState<any[]>([]);
    const { branchList } = useSelector((state: any) => state.branch);
    const { bookingServiceTypeList } = useSelector((state: any) => state.serviceType);
    const [isChecked, setIsChecked] = useState(true);
    const [filterTypeList, setFilterTypeList] = useState<any[]>([]);

    const validationSchema = object().shape({
        servicetypeTyId: number()
        .transform((value) => Number.isNaN(value) ? null : value )
            .required('serviceTypeName is required'),
        // category: string()
        //     .required('category is required'),
        serviceName: string()
            .required('Service Name is required'),
        tagLine: string()
            .required('TagLine is required'),
        serviceDuration: string()
            .required('serviceDuration is required'),
        description: string()
            .required('Description is required'),
        isOnlineBooking: boolean()
            .required('isOnlineBooking is required'),
    });

    useEffect(() => {
        if(bookingServiceTypeList!=null && bookingServiceTypeList.length > 0){
            console.log(bookingServiceTypeList)
            setFilterTypeList(bookingServiceTypeList);
        }
    }, [bookingServiceTypeList])

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        if (seletedBranches != null && seletedBranches.length == 0) {
            setErrorMessageContact('Please select atleast one Branch')
            return;
        }
        const branchObjList: any = [];
        seletedBranches.forEach((element) => {
            let branch = branchList.find((x: any) => x.id == element);
            if (branch != null) {
                branchObjList.push({
                    branch: branch,
                    branchId: element,
                    serviceId: 0
                })
            }
        });
        data.ServiceBranches = (branchObjList != null) ? branchObjList : [];
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };


    // const effectSeviceCall = useRef(false);
    // useEffect(() => {
    //     if (!effectSeviceCall.current) {
    //       //  dispatch(getBranch());
    //        // dispatch(getBookingServiceTypeData());
    //         effectSeviceCall.current = true;
    //     }
    // }, []);

    const onSelectContact = (e: any) => {
        setErrorMessageContact('')
        setSelectedBranches(e)
    };

    const handleToggle = () => {
        setIsChecked(prevState => !prevState);
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="md:flex md:flex-col flex flex-col justify-start items-start gap-3  w-full py-2">
                    <div className="flex fles-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5 ">
                        <div className="flex fles-row md:items-start md:flex-col gap-2 md:justify-start justify-center items-center ">
                            Booking Branches
                            <SelectBox
                                className="h-[52px] w-[300px] text-base text-left bg-white-full border"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={true}
                                name="frameFiftySix"
                                options={branchOptions}
                                isSearchable={true}
                                placeholder="Branches"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                variant="outline"
                                onChange={(e) => onSelectContact(e)}
                            />
                        </div>
                        <div className="flex fles-row gap-2 justify-center items-center mt-3">
                            <label className="inline-flex items-center  cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer" {...register('isOnlineBooking')} checked={isChecked} onChange={handleToggle} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-green-dark rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white-full after:border-white-full after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-dark"></div>
                            </label>

                            Online Booking

                        </div>
                    </div>
                    <div className="w-full flex ">
                        <div className="border border-solid border-gray-300 flex flex-col items-start justify-start p-[22px] sm:px-5 rounded-md shadow-bs w-full">
                            <div className="flex flex-row md:gap-10 items-start justify-between w-full">
                                Service Details
                            </div>
                            <div className=" md:flex md:flex-col mt-8 grid grid-cols-3 gap-4 w-full">
                                <div className="relative col-span-1 w-full">
                                    <label className="block mb-2 text-left text-sm">Service Type Name
                                    </label>
                                    <select className={`border border-gray-200 border-solid text-xs rounded-lg p-4 block w-full text-blue_gray-500 ${errors.servicetypeTyId ? 'is-invalid' : ''} `}
                                        {...register('servicetypeTyId')}>
                                        {filterTypeList && filterTypeList?.map((serviceType: any, index: number) => (
                                            <option key={index} value={serviceType.id}>{serviceType.serviceTypeName}</option>
                                        ))}
                                    </select>
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.servicetypeTyId?.message?.toString()}</div>
                                </div>
                                {/* <div className="relative  col-span-1 w-full">
                                    <label className="block mb-2 text-left text-sm">
                                        Service Type
                                    </label>
                                    <select className={`border border-gray-200 border-solid rounded-lg block w-full p-3 text-blue_gray-500 mb-2 text-left text-sm ${errors.category ? 'is-invalid' : ''}`}
                                        {...register('category')}
                                    >
                                        {bookingServiceTypeList?.map((serviceType: any) => (
                                            <option key={serviceType.id} value={serviceType.category}>{serviceType.category}</option>
                                        ))}
                                    </select>
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.category?.message?.toString()}</div>
                                </div> */}
                                <div className="relative  col-span-1 w-full">
                                    <label className="block mb-2 text-left text-sm">
                                        Service Name
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${errors.serviceName ? 'is-invalid' : ''}`} placeholder="Service"

                                        {...register('serviceName')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.serviceName?.message?.toString()}</div>
                                </div>
                            </div>
                            <div className="flex md:flex-col items-center justify-start w-full md:w-full">
                                <div className=" md:flex md:flex-col grid grid-cols-3 gap-4 w-full">
                                    <div className="relative  col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Service Tagline
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 `} placeholder="Give a quick intro to your Servcie"
                                            {...register('tagLine')}
                                        />
                                    </div>
                                    <div className="relative  col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Service Duration
                                        </label>
                                        <select className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 text-blue_gray-500  `}
                                            {...register('serviceDuration')}
                                        >
                                            <option value="1hour">1 hour</option>
                                            <option value="15minutes">15 minutes</option>
                                            <option value="30minutes">30 minutes</option>
                                            <option value="45minutes">45 minutes</option>
                                        </select>
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.serviceDuration?.message?.toString()}</div>
                                    </div>
                                    <div className="relative  col-span-1 w-full">
                                        <label className="block mb-2 text-left text-sm">
                                            Service Description
                                        </label>
                                        <textarea
                                            className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                            {...register('description')}
                                            placeholder="Describe what you offer and why clients should book"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex fles-row justify-between items-start h-auto w-full gap-2 mt-5">
                        <div className="flex fles-row gap-2 justify-center items-center ">
                            <Link to='/services'>
                                <button type="button" className="flex gap-2 px-4 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange">
                                    <p className="mt-0.5">Cancel</p>
                                </button>
                            </Link>
                        </div>
                        <div className="flex fles-row gap-2 justify-center items-center">
                            <button type="submit" className="flex gap-2 px-4 p-2 text-sm font-medium text-white-full hover:text-black bg-green-dark rounded-md border border-gray-200 hover:bg-orange">
                                <p className="mt-0.5">Next</p>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddServiceStepOne;
