import { Modal } from 'flowbite-react';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { getBranch } from '../../../store/slice/branch-slice';
import { useSelector } from 'react-redux';
import { fetchStaffData } from '../../../store/slice/staff-slice';
import { addContact, fetchContactData, putContactData } from '../../../store/slice/contact-slice';
import { SelectBox } from '../../SelectBox';

function AddContact({ openDrawer, Close, modalType, contact }: { openDrawer: boolean, Close: any, modalType: string, contact?: any }) {
    const [title] = useState((modalType == 'Add' ? 'Add New Contact' : 'Edit Contact'))
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingContact, setExistingContact] = useState(contact);
    const { branchList } = useSelector((state: any) => state.branch);
    const { staffList } = useSelector((state: any) => state.staff);
    const [errorMessageContact, setErrorMessageContact] = useState<string>('');
    const [seletedBranches, setSelectedBranches] = useState<any[]>([]);
    const [seletedStaff, setSelectedStaff] = useState<any[]>([]);
    const [openModalSecond, setOpenModalSecond] = useState(false);
    const [modalPlacementSecond, setModalPlacementSecond] = useState('center')
    const [contactPersonalData, setContactPersonalData] = useState<any>();



    const effectSeviceCall = useRef(false);
    useEffect(() => {
        if (!effectSeviceCall.current) {
            dispatch(getBranch());
            dispatch(fetchStaffData());
        }
        return () => { effectSeviceCall.current = true; };
    }, [dispatch]);

    const onSelectStaff = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(e.target.value);
        setErrorMessageContact('')
        setSelectedStaff([e.target.value])
    };

    const BranchOptionsLists = branchList ? branchList.map((branch: any) => ({
        label: branch.name,
        value: branch.id
    })) : [];

    const onSelectBranch = (e: any) => {
        console.log(e);
        setErrorMessageContact('')
        setSelectedBranches(e)
    };

    const validationSchema = object().shape({
        firstName: string()
            .required('FirstName is required'),
        lastName: string()
            .required('LastName is required'),
        email: string()
            .required('Email is required')
            .email('Email is invalid'),
        phoneNumber: string()
            .required('PhoneNumber is required'),
        // address: object().shape({
        //     addressLine1: string()
        //         .required('AddressLine1 is required'),
        //     addressLine2: string()
        //         .required('AddressLine2 is required'),
        //     city: string()
        //         .required('City is required'),
        //     provice: string()
        //         .required('Provice is required'),
        //     country: string()
        //         .required('Country is required'),
        //     postalCode: string()
        //         .required('PostalCode is required'),
        // })
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        if (modalType === 'Add') {
            if (seletedBranches != null && seletedBranches.length === 0) {
                setErrorMessageContact('Please select atleast one branch')
                return;
            }
            const branchObjList: any = [];
            seletedBranches.forEach((element) => {
                let branch = branchList.find((x: any) => x.id === element);
                if (branch != null) {
                    branchObjList.push({
                        branch: branch,
                        branchId: element,
                        ContactId: 0
                    })
                }
            });

            data.ContactInfoAssigns = [];
            data.ContactInfoAssigns.push({
                staffId: parseInt(seletedStaff[0]),
                contactId: 0
            })
            data.ContactBranches = (branchObjList != null) ? branchObjList : [];
            setContactPersonalData(data);
            // data.contactAddress = [];
            // data.contactAddress.push(data.address);
            setOpenModal(Close);
            setOpenModalSecond(true);
            console.log(JSON.stringify(data, null, 2));

            // dispatch(addContact(data))
            //     .unwrap()
            //     .then(() => {
            //         dispatch(fetchContactData())
            //         navigate("/contact");
            //     })
            //     .catch(() => {
            //         // setLoading(false);
            //     })
            //     .finally(() => {
            //         setTimeout(() => {
            //         }, 300);
            //     });
        } else {
            setOpenModal(Close);
            setOpenModalSecond(true);
            console.log(JSON.stringify(data, null, 2));
            // data.contactAddress = [];
            // data.contactAddress.push(data.address);
            // dispatch(putContactData({ id: contact.id, data }))
            //     .unwrap()
            //     .then(() => {
            //         dispatch(fetchContactData())
            //         navigate("/contact");
            //     })
            //     .catch(() => {
            //         // setLoading(false);
            //     })
            //     .finally(() => {
            //         setTimeout(() => {
            //         }, 300);
            //     });
        }

    };

    const validationSecondSchema = object().shape({
        address: object().shape({
            addressLine1: string()
                .required('AddressLine1 is required'),
            addressLine2: string()
                .required('AddressLine2 is required'),
            city: string()
                .required('City is required'),
            provice: string()
                .required('Provice is required'),
            country: string()
                .required('Country is required'),
            postalCode: string()
                .required('PostalCode is required'),
        })
    });

    const {
        register: secondForm,
        handleSubmit: handleSecondSubmit,
        reset: secondReset,
        setValue: secondSetValue,
        formState: { errors: secondError }
    } = useForm({
        resolver: yupResolver(validationSecondSchema)
    });

    const onSubmitSecondForm = (data: any) => {
        // Transform data to include only contactAddress array
        if (data.address) {
            data.contactAddress = [data.address]; // Convert address to array format
            delete data.address; // Remove standalone address field
        } else {
            data.contactAddress = [];
        }

        let finalObj = { ...contactPersonalData, ...data };

        if (modalType === 'Add') {
            // Handle Add Contact
            console.log(JSON.stringify(finalObj, null, 2));

            dispatch(addContact(finalObj))
                .unwrap()
                .then(() => {
                    dispatch(fetchContactData());
                    navigate("/contact");
                })
                .catch((error) => {
                    console.error("Error adding contact:", error);
                    // Handle error, potentially show a message to the user
                })
                .finally(() => {
                    setTimeout(() => { }, 300);
                });
        } else {
            // Handle Update Contact
            console.log(JSON.stringify(finalObj, null, 2));

            dispatch(putContactData({ id: contact.id, data: finalObj }))
                .unwrap()
                .then(() => {
                    dispatch(fetchContactData());
                    navigate("/contact");
                })
                .catch((error) => {
                    console.error("Error updating contact:", error);
                    // Handle error, potentially show a message to the user
                })
                .finally(() => {
                    setTimeout(() => { }, 300);
                });
        }
        reset();
        secondReset();
        setOpenModalSecond(false);
    };

    useEffect(() => {
        if (modalType === 'Edit' && contact) {
            setExistingContact(contact);
            reset(contact);
        }
    }, [contact, modalType, reset]);


    return (
        <>
            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 flex items-center justify-center max-w-full`}
                show={openDrawer}
                onClose={() => { setOpenModal(Close); reset() }}
                initialFocus={emailInputRef}
                size="lg"
            >
                <Modal.Header className="bg-white-full p-5">
                    <span className="text-blue_gray-900 text-xl">{title}</span>
                </Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-3">
                            <div className=" md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-2">
                                        First Name
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                    ${errors.firstName ? 'is-invalid' : ''}`}
                                        placeholder="Enter First Name"
                                        {...register('firstName')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.firstName?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-2">
                                        Last Name
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                    ${errors.lastName ? 'is-invalid' : ''}`}
                                        placeholder="Enter Last Name"
                                        {...register('lastName')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.lastName?.message?.toString()}</div>
                                </div>
                            </div>
                            <div className=" md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-2">
                                        Email
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                    ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Enter Your Email"
                                        {...register('email')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.email?.message?.toString()}</div>
                                </div>
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-2">
                                        Assignee
                                    </label>
                                    <select id="staff" className="border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500"
                                        onChange={(e) => onSelectStaff(e)}
                                        value={seletedStaff[0] || ""}
                                    >
                                        <option value="" disabled>Select one</option>

                                        {staffList?.map((staff: any, index: any) => (
                                            <>
                                                <option key={index} value={staff.id}>{staff.email}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className=" md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                <div className="relative max-w-sm col-span-2">
                                    <label className="block mb-2">
                                        PhoneNumber
                                    </label>
                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mb-2 placeholder:text-blue_gray-500 h ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        placeholder="0774589215"
                                        {...register('phoneNumber')}
                                    />
                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.phoneNumber?.message?.toString()}</div>


                                    {/* <div className="relative max-w-sm col-span-2">
                                        <label className="block mb-2">
                                            Address
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mb-2 placeholder:text-blue_gray-500 h ${errors?.address?.addressLine1 ? 'is-invalid' : ''}`}
                                            placeholder="address"
                                            {...register('address.addressLine1')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.address?.addressLine1?.message?.toString()}</div>

                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mb-2 placeholder:text-blue_gray-500`}
                                            placeholder="AddressLine2"
                                            {...register('address.addressLine2')}
                                        />
                                    </div> */}
                                </div>
                                <div className="relative max-w-sm col-span-2 w-full">
                                    <div className="max-w-sm col-span-1 w-full">
                                        <div className="max-w-sm w-full">
                                            <label className="block mb-2">
                                                Branch Names
                                            </label>
                                            <SelectBox
                                                className="h-[52px] text-base bg-white-full text-left w-full"
                                                placeholderClassName="text-blue_gray-600 text-txtInterMedium14"
                                                indicator={
                                                    <svg
                                                        width="15"
                                                        className="fill-current"
                                                        height="15"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512">
                                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                    </svg>
                                                }
                                                isMulti={true}
                                                name="frameFiftySix"
                                                options={BranchOptionsLists}
                                                isSearchable={true}
                                                placeholder="Branches"
                                                shape="round"
                                                color="indigo_50"
                                                size="xs"
                                                variant="outline"
                                                onChange={(e) => onSelectBranch(e)}
                                            />
                                            {/* <div className="font-medium text-xs text-red-600" >{errors?.branchName?.message?.toString()}</div> */}
                                            {/* <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mt-6  mb-2 placeholder:text-blue_gray-500 h
                                    ${errors.address?.provice ? 'is-invalid' : ''}`}
                                                placeholder="Provice"
                                                {...register('address.provice')}
                                            />
                                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.address?.provice?.message?.toString()}</div>

                                            <select id="countries" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${errors.address?.country ? 'is-invalid' : ''}`}
                                                {...register('address.country')}>
                                                <option value="">Country</option>
                                                <option value="UnitedStates">United States</option>
                                                <option value="Canada">Canada</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                            </select>
                                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.address?.country?.message?.toString()}</div> */}
                                            {/* <div className="md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                                <div className="relative max-w-sm col-span-2">
                                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                            ${errors.address?.city ? 'is-invalid' : ''}`}
                                                        placeholder="City"
                                                        {...register('address.city')}
                                                    />
                                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.address?.city?.message?.toString()}</div>
                                                </div>
                                                <div className="relative max-w-sm col-span-2">
                                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500
                                            ${errors.address?.postalCode ? 'is-invalid' : ''}`}
                                                        placeholder="Zip postal"
                                                        {...register('address.postalCode')}
                                                    />
                                                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.address?.postalCode?.message?.toString()}</div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex md:flex-col gap-2 flex-row justify-start w-full pb-3">
                                <div className="flex flex-row gap-2 items-end justify-end w-full">
                                    <button onClick={() => {
                                        reset()
                                        setOpenModal(Close);
                                    }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Cancel</p>
                                    </button>
                                    <button type="submit" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black"
                                    >
                                        <p>Next</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>



            <Modal
                className={`backdrop-blur-sm backdrop-brightness-75 items-center justify-center w-full`}
                show={openModalSecond}
                position={modalPlacementSecond}
                onClose={() => { setOpenModalSecond(false); secondReset(); }}
                size="lg"
            >
                <Modal.Header className='bg-white-full p-5'>Contact Address</Modal.Header>
                <Modal.Body className="bg-white-full p-5">
                    <form onSubmit={handleSecondSubmit(onSubmitSecondForm)}>
                        <div className="space-y-3">
                            <div className=" md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                <div className="relative max-w-sm col-span-2">
                                    <div className="relative max-w-sm col-span-2">
                                        <label className="block mb-2">
                                            Address
                                        </label>
                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mb-2 placeholder:text-blue_gray-500 h ${secondError?.address?.addressLine1 ? 'is-invalid' : ''}`}
                                            placeholder="address"
                                            {...secondForm('address.addressLine1')}
                                        />
                                        <div className="font-medium mt-2 text-xs text-red-600" >{secondError?.address?.addressLine1?.message?.toString()}</div>

                                        <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 mb-2 placeholder:text-blue_gray-500`}
                                            placeholder="AddressLine2"
                                            {...secondForm('address.addressLine2')}
                                        />
                                    </div>
                                </div>
                                <div className="relative max-w-sm col-span-2 w-full">
                                    <div className="max-w-sm col-span-1 w-full">
                                        <div className="max-w-sm w-full">
                                            <label className="block mb-2">
                                                Provice
                                            </label>
                                            <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3  mb-2 placeholder:text-blue_gray-500 h
                                    ${secondError.address?.provice ? 'is-invalid' : ''}`}
                                                placeholder="Provice"
                                                {...secondForm('address.provice')}
                                            />
                                            <div className="font-medium mt-2 text-xs text-red-600" >{secondError?.address?.provice?.message?.toString()}</div>

                                            <select id="countries" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 ${secondError.address?.country ? 'is-invalid' : ''}`}
                                                {...secondForm('address.country')}>
                                                <option value="">Country</option>
                                                <option value="UnitedStates">United States</option>
                                                <option value="Canada">Canada</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                            </select>
                                            <div className="font-medium mt-2 text-xs text-red-600" >{secondError?.address?.country?.message?.toString()}</div>
                                            <div className="md:flex md:flex-col grid grid-cols-4 gap-4 my-4">
                                                <div className="relative max-w-sm col-span-2">
                                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h
                                            ${secondError.address?.city ? 'is-invalid' : ''}`}
                                                        placeholder="City"
                                                        {...secondForm('address.city')}
                                                    />
                                                    <div className="font-medium mt-2 text-xs text-red-600" >{secondError?.address?.city?.message?.toString()}</div>
                                                </div>
                                                <div className="relative max-w-sm col-span-2">
                                                    <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500
                                            ${secondError.address?.postalCode ? 'is-invalid' : ''}`}
                                                        placeholder="Zip postal"
                                                        {...secondForm('address.postalCode')}
                                                    />
                                                    <div className="font-medium mt-2 text-xs text-red-600" >{secondError?.address?.postalCode?.message?.toString()}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex md:flex-col gap-2 flex-row justify-start w-full pb-3">
                                <div className="flex flex-row gap-2 items-end justify-end w-full">
                                    <button onClick={() => {
                                        setOpenModal(Close);
                                        setOpenModalSecond(false);

                                    }} type="button" className="flex gap-3 p-2 text-sm font-medium text-black  hover:text-green-dark rounded-md border">
                                        <p>Back</p>
                                    </button>
                                    <button type="submit" className="flex gap-3 p-2 px-4 text-sm font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                                        <p>Save</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}

export { AddContact };
