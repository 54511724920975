import { ErrorResult } from "../store/model/errorResult";
import axiosInstance from "./axiosInstance";


const addContact = (data: any) => {
  return axiosInstance.post("contact", data);
};

const getContactData = async () => {
  try {
    const response = await axiosInstance.get("contact");
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const updateContactData = async (id: any, data: any) => {
  try {
    const response = await axiosInstance.put(`contact/${id}`, data);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const deleteContactData = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`contact/${id}`);
    return response;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const ContactService = {
  addContact,
  getContactData,
  updateContactData,
  deleteContactData
}

export default ContactService;