import React, { useEffect, useRef, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../store/hooks/redux-hooks';
import { addlocation, deleteLocation, getAllLocation } from '../../store/slice/location-slice';
import Loading from '../../components/Loading/loading';

const AdminPostcode: React.FC = () => {
  const dispatch = useAppDispatch();
  const [postcode, setPostcode] = useState('');
  const { postCode } = useSelector((state: any) => state.location);
  const [isLoading, setIsLoading] = useState(true);

  const effectServiceCall = useRef(false);
  useEffect(() => {
      if (!effectServiceCall.current) {
          dispatch(getAllLocation()).finally(()=>{
            setIsLoading(false);
          });
      }
      return () => { effectServiceCall.current = true; };
  }, [dispatch]);

  const onDeletePostCode = (id: number, index: number) => {
    setIsLoading(true);
    dispatch(deleteLocation(id))
    .unwrap()
    .then(() => {
      dispatch(getAllLocation());
    })
    .catch(() => {
      setIsLoading(false);
    })
    .finally(() => {
      setPostcode('');
      setIsLoading(false);
    });
  };

  const onAddPostCode = () => {
    if(postcode == null || postcode == ''){
      toast.error(`Please Enter Post Code`);
      return;
    }

    let data =[{
      postCode: postcode,
      id:0
    }]
    setIsLoading(true);
    dispatch(addlocation(data))
    .unwrap()
    .then(() => {
        setPostcode('');
        dispatch(getAllLocation());
    })
    .catch(() => {
        setIsLoading(false);
    })
    .finally(() => {
      setPostcode('');
      setIsLoading(false);
    });
  }

  return (
    <div className="admin-postcode-section p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Find and Manage Postcodes</h2>
      <div className="relative flex items-center gap-3 justify-start items-baseline">
        <input
          type="text"
          placeholder="Enter UK postcode"
          maxLength={8}
          value={postcode}
          className="border rounded-l w-75 py-2 px-3 mb-4"
          onChange={(data: any)=> setPostcode(data.target.value)}
        />
             <button type="button" onClick={onAddPostCode} className="flex py-2 p-3 px-5 me-2 mb-2font-medium text-white-full bg-green-dark rounded-md border border-gray-200 hover:bg-orange hover:text-black">
                    <p>Add</p>
                  </button>
      </div>
      <div className="mt-4">
        <div className="w-full h-screen">
        {(isLoading) && <Loading></Loading>}
            <div className="grid grid-cols-4 gap-4">
            {(postCode!=null && postCode.length > 0) && postCode.map((item: any, index: number) => (
              <div key={item.id} className="flex items-center justify-between border p-2 rounded-lg shadow-sm bg-white mb-4">
                <span>{item.postCode}</span>
                <button type="button" className="p-2 bg-transparent border-2 rounded-md text-red-600" onClick={() => onDeletePostCode(item.id, index)}>
                  <MdDeleteOutline className="w-5 h-5" />
              </button>
              </div>
            ))}
            </div>
            </div>
        </div>
    </div>
  );
};

export default AdminPostcode;
